import {
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  SwipeableDrawer,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  createStyles
} from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { NuvvenDivider } from "../views/Summary/Components/Divider";

interface IFilterData {
  type: string;
  values: string[];
}
interface IProps {
  filters: IFilterData[];
  open: boolean;
  handleClose(): void;
  handleFilterChange(type: string, value: string): void;
  searchFilters: any;
  handleOpenFilterView(): void;
  clearFilters(): void;
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      "& .MuiTabs-scroller": {
        "& .MuiButtonBase-root": {
          textAlign: "left",
          textTransform: "capitalize",
          "& .MuiTab-wrapper": {
            alignItems: "flex-start"
          }
        }
      }
    },
    tab: {
      "& .MuiTab-wrapper": {
        alignItems: "self-start",
        justifyContent: "flex-start"

      }
    },
    drawerPaper: {
      zIndex: 1300,
      height: "calc(100% - 15rem)",
      top: "10rem",
      padding: 20,
      overflow: "hidden",
      borderRadius: 10,
      width: "50%",
      margin: "0 auto"
    }
  })
);

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: 400, marginTop: 25 }}
    >
      {value === index && (
        <Box pl={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: any) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const FiltersView = (props: IProps) => {

  const classes = useStyles();

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const container = window !== undefined ? () => document.body : undefined;
  const iOS = (typeof window !== 'undefined') && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Drawer
      anchor="top"
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="max-width-dialog-title"
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
      ModalProps={{
        keepMounted: true,
      }}
      container={container}
    >
      <div>
        <Grid container item xs={12} justify="space-between">
          <Grid item >
            <Typography variant="h3">FILTERS</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Clear Filters">
              <IconButton onClick={() => props.clearFilters()} aria-label="delete">
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton onClick={() => props.handleClose()} aria-label="delete">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <NuvvenDivider noMargin />
        </Grid>
        <Grid container item xs={12} spacing={2} style={{}} >
          <Grid item xs={4}>
            <Tabs
              value={activeTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              orientation="vertical"
              className={classes.tabs}
            >
              {props.filters.map((filter: IFilterData, index: number) => (
                <Tab key={index} label={filter.type} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={8}>
            {props.filters.map((filter: IFilterData, index: number) => (
              <TabPanel value={activeTab} key={filter.type} index={index}>
                <Grid container >
                  {filter.values.map((value: string, idx: number) => (
                    <Grid container item xs={6}>
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            checked={props.searchFilters[filter.type].includes(value)}
                            onChange={() => props.handleFilterChange(filter.type, value)}
                          />
                        }
                        label={value}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>

            ))}
          </Grid>
        </Grid>
      </div>
    </Drawer>

  );
};
