import { gql } from "apollo-boost";

export const GET_VEHICLE = gql`
  query Vehicle($id: String!) {
    vehicle(id: $id) {
      bodyType
      isGhostVehicle
      colour
      fuelType
      licencePlate
      vin
      make
      model
      year
      price
      status
      telemetryDeviceId
      transmission
      branch
      vehicleType
      branchDetails {
        id
        name
      }
      imageUrl
      deleted
      id
      pcoNumber
      frogDiagram
      activeBookingSchedule{
        id
        booking{
          referenceNumber
          status
          customer {
            id
            firstName
            lastName
            email
            phoneNumber {
              phone
              country
            }
          }
          businessCustomer {
            id
            businessName
            billing {
              phoneNumber {
                phone
                country
              }
              email
            }
          }
          pickupDateTime
          dropoffDateTime
          pickupServiceLocation {
            name
          }
          pickupOtherLocation {
            fullAddress
          }
          dropoffServiceLocation {
            name
          }
          dropoffOtherLocation {
            fullAddress
          }
        }
      }
      bookingSchedules {
        booking {
          id
        }
        endDate
        id
        startDate
        status
      }
      features
      vehicleState {
        generalChecks {
          name
          status
          images
        }
        defaultAccesories {
          name
          status
          images
        }
        bookedAccesories {
          name
          type
          quantity
          description
        }
        id
        odometerReading
        fuelStatus
        batteryStatus
        updatedAt
        createdAt
        updatedBy {
          firstName
          lastName
        }
        createdBy {
          firstName
          lastName
        }
      }
      activeVehicleDamages {
        id
        frogDiagram
        referenceNumber
        approvalStatus
        branchId
        damages {
          title
          descriptions
          damageType
          damageKind
          damageArea
          images
          location {
            x
            y
          }
        }
      }
      tankCapacity
    }
  }
`;

export const GET_BOOKING_DATA_FOR_VEHICLE = gql`
  query consumerGetBooking($bookingId: String!) {
    consumerGetBooking(bookingId: $bookingId) {
      referenceNumber
      status
      customerType
      branch {
        timeZone
      }
      customer {
        id
        firstName
        lastName
        email
        phoneNumber {
          phone
          country
        }
      }
      businessCustomer {
        id
        businessName
        billing {
          phoneNumber {
            phone
            country
          }
          email
        }
      }
      pickupDateTime
      dropoffDateTime
      pickupServiceLocation {
        name
      }
      pickupOtherLocation {
        fullAddress
      }
      dropoffServiceLocation {
        name
      }
      dropoffOtherLocation {
        fullAddress
      }
    }
  }
`;


