import Container from '@material-ui/core/Container';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Storage } from "aws-amplify";
import styles from './index.module.css';
import { IAppState } from '../../store';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Authenticator from "../views/Auth"
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory } from 'react-router-dom';
import { AccountType, UserRole } from '../../reducers/auth/types';
import { CircularProgress, createStyles, withStyles } from '@material-ui/core';
import { getLocalizedBookingSyntex } from '../../utils/localized.syntex';

export const WhiteCircularProgress = withStyles(() =>
  createStyles({
    root: {
      color: '#fff !important',
      width: 20,
      height: 20
    },
  }),
)(CircularProgress);

interface Props {
  height: number
  logoSize: number
  accountScreen?: boolean
}

export const TopBar: React.FC<Props> = (props) => {
  const history = useHistory();
  const [logoUrl, setLogoUrl] = useState<any>("");
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const authState = useSelector((state: IAppState) => state.authReducer);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const [authDialogOpen, setAuthDialogOpen] = useState<boolean>(false);

  document.title = website.websiteTitle || website.organisation.name;

  useEffect(() => {
    if (website) {
      getLogoUpload(website.websiteLogo || website.organisation.logoUrl);
    }
  }, [website]);


  const getLogoUpload = async (logoUrl: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await Storage.get(logoUrl, config).then((result) => {
      return result;
    }).catch((err) => {
    });
    const response = await fetch(file as string).then((res) => {
      return res;
    });
    if (!response.ok) {
      setLogoUrl("");
      return;
    }
    setLogoUrl(file);
  };

  const hashttp = (url: string) => {
    return /\b(http:|https:)/.test(url);
  }

  const onSignIn = () => {
    setAuthDialogOpen(false);
  }

  const companyWebsiteURL = website.companyWebsite ? hashttp(website.companyWebsite) ? website.companyWebsite : `http://${website.companyWebsite}` : "/";

  return (
    <div className={styles.topBar} style={{ height: props.height || 70 }}>
      <Container>
        <div className={styles.topBarWrapper}>
          <a href={companyWebsiteURL} className={styles.topBarLogo} style={{ height: props.logoSize }}>
            {logoUrl ? <img src={logoUrl} alt="logo" /> : <WhiteCircularProgress size="small" thickness={5} />}
          </a>
          <div className="flex fill padding-left margin-left padding-right margin-right">
            <div style={{ fontWeight: 700, width: "85%" }} dangerouslySetInnerHTML={{ __html: website.websiteHeader || "" }}>
            </div>
          </div>
          {(website.tenancy.loginEnabled && (!props.accountScreen || (props.accountScreen && authState.accountType === AccountType.INDIVIDUAL) || (props.accountScreen && authState.accountType === AccountType.BUSINESS && user?.role !== UserRole.DRIVER))) && (
            <div className={styles.auth} onClick={() => props.accountScreen ? history.push("/") : authState.authUser?._id ? history.push("/account") : setAuthDialogOpen(true)}>
              <div className={styles.iconWrap}>
                {props.accountScreen ? <AddCircleIcon fontSize="small" /> : <AccountCircleIcon fontSize="small" />}
              </div>
              <span className="bold">{props.accountScreen ? `New ${getLocalizedBookingSyntex(country)}` : authState.authUser?._id ? "Account" : "Sign In"}</span>
            </div>
          )}
        </div>
      </Container>
      <Dialog open={authDialogOpen} onClose={() => setAuthDialogOpen(false)} maxWidth="md" >
        <div style={{ padding: 40 }}>
          <Authenticator onSignIn={onSignIn} />
        </div>
      </Dialog>
    </div>

  )
}