import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { ApolloError } from "apollo-boost";
import { Field, FieldProps, Form, Formik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import LaunchIcon from '@material-ui/icons/Launch';
import * as Yup from "yup";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { CREATE_CUSTOMER } from "../../../../../graphql/customers/createCustomerMutation";
import { useSnackBar } from "../../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../SnackbarWrapper/SnackbarWrapper";

import styles from './index.module.css';
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

import { formatGraphQLErrorMessage } from "../../../../utils";
import { Button, Checkbox, CheckboxProps, Fab, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { IAppState } from '../../../../../store';
import { useSelector } from 'react-redux';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { IBookingQuestionInput, ICustomerInfoInput, IAddress, IBookingCreateInput } from '../../../../../reducers/bookings/types';
import { ANSWER_TYPES, IBookingQuestion } from '../../../../../reducers/website/types';
import Hidden from '@material-ui/core/Hidden';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { AccountType, IUser } from '../../../../../reducers/auth/types';
import { getDefaultCountryCode, getLocalizedBookingSyntex } from "../../../../../utils/localized.syntex";
import AddressPicker from "../../../../AddressPicker/AddressPicker";
import AddNewDriver, { IDriverRow } from "./AddNewDriver";
import { SearchForm } from "../../../CustomerPortal/SearchForm";
import DeleteIcon from '@material-ui/icons/Delete';

const WhiteCircularProgress = withStyles(() =>
  createStyles({
    root: {
      color: '#fff !important',
      width: 30,
      height: 30
    },
  }),
)(CircularProgress);

interface IProps {
  updateCustomer: (data: { customer?: string, businessCustomer?: string, approvedDrivers?: string[], preBookingQuestions?: IBookingQuestionInput[], customerNote?: string, costCenter?: string, projectId?: string }) => void;
  onPrevious(values: ICustomerInfoInput): void;
  customerInfo: ICustomerInfoInput;
  branchId?: string;
  bookingData?: IBookingCreateInput;
  driverCount?: number;
  updateBookingDrivers: (data: IDriverRow[]) => void;
  bookingDrivers?: IDriverRow[];
}
const StyledInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // marginBottom: 20,
      '& .MuiOutlinedInput-input': {
        padding: '14px 14px'
      },
      '& .MuiOutlinedInput-multiline': {
        padding: '0px 4px'
      },
      '& .MuiOutlinedInput-root fieldset': {
        borderColor: '#e2e2e2',
        borderRadius: 8
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      },
      '& .MuiSelect-select': {
        padding: '10px 14px'
      },
      '& .MuiSelect-select ~ fieldset': {
        border: 0
      },
    },
  }),
)(TextField);

const ColoredCheckbox = withStyles({
  root: {
    color: 'var(--primary-color)',
    '&$checked': {
      color: 'var(--primary-color)',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export interface IAdditionalDriver {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  license: {
    authority: string;
    licenseNumber: string;
    dateOfExpiry: string;
    images: (string | object | undefined)[];
  };
  phoneNumber: {
    phone: string;
    country: string;
  };
  location: IAddress;
}

const SimpleCustomerView: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const authState = useSelector((state: IAppState) => state.authReducer)
  const branchData = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite.branches);
  const [driverCount, setDriverCount] = useState<number>(0);
  const [additionalDriverQuantity, setAdditionalDriverQuantity] = useState<number>(0);
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [costCenter, setCostCenter] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [availableProjectIds, setAvailableProjectIds] = useState<string[]>([]);
  const [driverList, setDriverList] = useState<any[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<IDriverRow | null>(null);

  const [createCustomer, { loading: creatingCustomer }] = useMutation(CREATE_CUSTOMER, {

  });

  useEffect(() => {
    if (user?.customer && user.customer.costCenters && costCenter) {
      setAvailableProjectIds(user.customer.costCenters?.find(cc => cc.name === costCenter)?.projectIds || [])
    }
  }, [costCenter, user])

  const onSubmit = (values: ICustomerInfoInput) => {
    if (website.organisation.enableCostCenter && user?.customer && !costCenter) {
      return snackbar({
        message: "Cost Center info missing!",
        variant: SnackBarVariant.ERROR
      })
    }
    const driversId = driverList.map(driver => driver.id)
    const newCustomer = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      location: values.location
    };
    const customerNote = values.customerNote
    const preBookingQuestions = values.preBookingQuestions.map((bq) => {
      return {
        question: bq.question,
        answerType: bq.answerType,
        answer: bq.answer?.toString() || ''
      }
    })
    if (user && user.customerId) {
      props.updateCustomer({ customer: user.customerId,approvedDrivers: driversId,  preBookingQuestions, customerNote, costCenter, projectId });
    } else if (user && user.businesscustomers) {
      props.updateCustomer({ businessCustomer: user.businesscustomers.id,approvedDrivers: driversId, preBookingQuestions, customerNote });
    }
    else {
      createCustomer({
        variables: { customer: newCustomer }
      })
        .then((result: any) => {
          const { data } = result
          if (data && data.consumerCreateCustomer) {
            const driverArr: string[] = [];
            if (data.consumerCreateCustomer.approvedDrivers) {
              data.consumerCreateCustomer.approvedDrivers.forEach((driver: any) => {
                driverArr.push(driver.id);
              });
            }
            props.updateCustomer({ customer: data.consumerCreateCustomer.id, preBookingQuestions, customerNote });
            return
          }
        })
        .catch((error: ApolloError) => {
          snackbar({
            message: formatGraphQLErrorMessage(error.message),
            variant: SnackBarVariant.ERROR
          })
        });
    }
  };

  const customerSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email(),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.object().shape({
      phone: Yup.string()
        .required("Phone number is required.")
        .test('phone test', 'Invalid phone Number', (value) => {
          if (value) {
            let tempValue: string = value || '';
            return isValidPhoneNumber(tempValue);
          }
          return true;
      }),
    }),
    agreeToTerms: Yup.boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "The terms and conditions must be accepted."),
  });

  const validateAnswer = (value: any, required: boolean, expected?: string) => {
    let error;
    if (required) {
      if (expected === "TRUE" && !value) {
        error = "Error"
      }
      if (expected === "FALSE" && value) {
        error = "Error"
      }
      if (expected === "TEXT" && !value) {
        error = "Error"
      }
      if (expected === "YES" && value !== "YES") {
        error = "Error"
      }
      if (expected === "NO" && value !== "NO") {
        error = "Error"
      }
    }
    return error;
  }

  const getFormattedBookingQuestions = () => {
    if (bookingQuestions && bookingQuestions?.length) {
      return bookingQuestions.map((bookingQuestion, index) => {
        return {
          question: bookingQuestion.question,
          answerType: bookingQuestion.answerType,
          answer: props.customerInfo && props.customerInfo.preBookingQuestions && props.customerInfo.preBookingQuestions[index]?.answer
        }
      })
    }
    return []
  }

  let bookingQuestions: IBookingQuestion[] = [];
  const branch = branchData.find(branch => branch.id === props.branchId);
  if (branch) {
    let _questions: IBookingQuestion[] = [];
    branch.preBookingQuestions && branch.preBookingQuestions?.length && branch.preBookingQuestions.forEach((bq, idx) => {
      bq.B2B2C && bookingQuestions.push(bq);
    })
  }

  useEffect(() => {
    if (props.bookingData && props.bookingData.addonRequirements) {
      const addon = props.bookingData.addonRequirements.find((item: any) => item.name === "ADDITIONAL_DRIVER");
      if (addon) {
        setAdditionalDriverQuantity(addon.quantity);
      }
    }
  }, [props.bookingData?.addonRequirements]);

  useEffect(() => {
    if (props.bookingDrivers?.length) {
      setDriverList(props.bookingDrivers)
      setDriverCount(props.bookingDrivers.length)
    }
  }, [props.bookingDrivers])

  const addExistingDriver = (data: IDriverRow) => {
    const existingDriver = driverList.some((driver) => driver.email === data.email)
    if (existingDriver) {
      return snackbar({
        message: "Selected driver already added.",
        variant: SnackBarVariant.ERROR
      });
    } else {
      if (data) {
        if (driverCount < additionalDriverQuantity) {
          const driverData: any[] = [...driverList, data];
          setDriverList(driverData)
          setDriverCount((prevCount) => prevCount + 1);
          props.updateBookingDrivers(driverData)
          snackbar({
            message: "Driver saved successfully.",
            variant: SnackBarVariant.SUCCESS
          })
        } else {
          snackbar({
            message: "Additional Drivers cannot be more than Addon quantity",
            variant: SnackBarVariant.ERROR
          });
        }
      }
    }
  }

  const handleRemoveDriver = (newDriver: IDriverRow) => {
    const filteredDrivers = driverList.filter((driver) => driver.email !== newDriver.email)
    setDriverList(filteredDrivers)
    setDriverCount((prevCount) => prevCount - 1);
    props.updateBookingDrivers(filteredDrivers)
  }

  return (
    <Formik
      validationSchema={!user?.id && customerSchema}
      initialValues={{
        ...props.customerInfo,
        agreeToTerms: false,
        preBookingQuestions: getFormattedBookingQuestions(),
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (values.location.fullAddress && (!values.location.street || !values.location.zipcode)) {
          return snackbar({
            message: "Street/zipcode is required",
            variant: SnackBarVariant.ERROR,
          });
        }
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {(formikProps) => (
        <Form className={styles.container}>
          <Grid container>
            <Grid item container xs={12}>
              {(user && user.id) ?
                <Grid item xs={12}>
                  <Typography variant="body1" className="padding-bottom text-center semi-bold">{getLocalizedBookingSyntex(country)} for</Typography>
                  {authState.accountType === AccountType.INDIVIDUAL ? <div className={styles.box}>
                    <div className={styles.avatar}>
                      <span className="font-large bold">{user.firstName.slice(0, 1).toUpperCase()}{user.lastName.slice(0, 1).toUpperCase()}</span>
                      {/* <img src="https://picsum.photos/200/200/?blur=10" /> */}
                    </div>
                    <div className="flex fill col-flex margin-left">
                      <Typography variant="h3" className="semi-bold" style={{ marginLeft: 1, marginBottom: 4 }}>{user.firstName} {user.lastName}</Typography>
                      <span className="flex cross-center font-medium opacity50">
                        <EmailIcon style={{ fontSize: 17, marginRight: 3 }} />
                        <span>{user.email}</span>
                        <PhoneIcon style={{ fontSize: 17, marginRight: 3, marginLeft: 10 }} />
                        <span className="">{user.customer?.phoneNumber.phone}</span>
                      </span>
                    </div>
                  </div> : <div className={styles.box}>
                    <div className="flex fill col-flex">
                      <Typography variant="h3" className="semi-bold">{user.businesscustomers?.businessName || ""}</Typography>
                      <Typography variant="body1" className="opacity50" style={{ marginBottom: 15 }}>Business account</Typography>
                      <span className="flex cross-center font-medium opacity50">
                        <span className="margin-right">Contact: {user.firstName} {user.lastName}</span>
                        <EmailIcon style={{ fontSize: 17, marginRight: 3 }} />
                        <span>{user.email}</span>
                        <PhoneIcon style={{ fontSize: 17, marginRight: 3, marginLeft: 10 }} />
                        <span className="">{user.phoneNumber.phone}</span>
                      </span>
                    </div>
                  </div>}
                </Grid>
                :
                <Grid container item spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field name="email">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            variant="outlined"
                            label={"Email*"}
                            {...field}
                            placeholder="Enter your email"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="firstName">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            variant="outlined"
                            label={"First Name*"}
                            {...field}
                            placeholder="Enter first name"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="lastName">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            variant="outlined"
                            label={"Last Name*"}
                            {...field}
                            placeholder="Enter last name"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name={"phoneNumber.phone"}
                      required
                    >
                      {({ field, meta: { touched, error } }: FieldProps) => {
                        return (
                          <div className={`phone-input-container`}>
                            <div style={{ border: `1px solid ${(touched && Boolean(error)) ? '#D13135' : '#e2e2e2'}`, borderRadius: 8 }}>
                              <PhoneInput
                                {...field}
                                international={false}
                                defaultCountry={getDefaultCountryCode(website.organisation.address.country)}   //
                                placeholder="Enter phone number *"
                                className={(touched && Boolean(error)) ? "PhoneInputInputError" : ""}
                                name={"phoneNumber.phone"}
                                onChange={(val) => {
                                  formikProps.setFieldValue('phoneNumber.phone', val)
                                }}
                                onCountryChange={(val) => {
                                  formikProps.setFieldValue('phoneNumber.country', val)
                                }}
                              />
                            </div>
                            <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AddressPicker
                      fieldName={"location"}
                      onChange={(address: IAddress) => {
                        if (address) {
                          formikProps.setFieldValue('location', address)
                        }
                      }}
                    />
                  </Grid>
                </Grid>}
              <div className={styles.bottomBar}>
                <Hidden smDown>
                  <div style={{ display: 'flex', flex: 3 }}></div>
                </Hidden>
                <div className="flex fill cross-center main-end" style={{ backgroundColor: '#fff' }}>
                  <button
                    className={styles.prevStepButton}
                    onClick={() => props.onPrevious(formikProps.values)}
                  >
                    <KeyboardArrowLeftIcon />
                    <span className="padding-right">Back</span>
                  </button>
                  <Hidden mdUp>
                    <button
                      className={`${styles.nextStepButton} ${styles.mobile}`}
                      type="submit"
                    >
                      {creatingCustomer ? <WhiteCircularProgress size="small" thickness={5} /> : 'Get Quote'}
                    </button>
                  </Hidden>
                  <Hidden smDown>
                    <button
                      className={styles.nextStepButton}
                      type="submit"
                    >
                      {creatingCustomer ? <WhiteCircularProgress size="small" thickness={5} /> : (website.organisation.closeGroupSharingEnabled ? 'Proceed' : 'Get Quote')}
                    </button>
                  </Hidden>
                </div>
              </div>
            </Grid>
            <Grid item container xs={12}>
              {(user && (user?.customer || user.businesscustomers) && additionalDriverQuantity) ? (
                <Grid item container>
                  <Grid xs={6}>
                    <SearchForm
                      updateData={addExistingDriver}
                      dataOptions={user.customer?.approvedDrivers ? user.customer?.approvedDrivers : user?.businesscustomers?.approvedDrivers}
                    />
                  </Grid>
                </Grid>
              ) : <></>}
              {(user?.businesscustomers || user?.customer?.id) && driverList?.length ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {driverList.map((driver: IDriverRow, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{`${driver.firstName} ${driver.lastName}`}</TableCell>
                          <TableCell>{driver.email}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <IconButton
                              color="secondary"
                              aria-label="delete"
                              onClick={() => handleRemoveDriver(driver)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : <></>}
            </Grid>

            {website.organisation.enableCostCenter && user?.customer?.costCenters?.length &&
              <Grid item container xs={12}>
                <Grid item xs={12} style={{ margin: '40px 0 30px 0' }}>
                  <Typography variant="h4" align="center" style={{ fontWeight: 700 }} >
                    Select Cost Center For Booking
                  </Typography>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={4}>
                    <div className={styles.dropdownWrap}>
                      <StyledInput
                        select
                        fullWidth
                        label={`Cost Center`}
                        name={"costCenter"}
                        variant="outlined"
                        onChange={(e) => {
                          setCostCenter(e.target.value)
                          setAvailableProjectIds(user?.customer?.costCenters?.find(cc => cc.name === e.target.value)?.projectIds || [])
                        }}
                      >
                        {user?.customer?.costCenters && user.customer.costCenters.map((cc) => (
                          <MenuItem key={cc.name} value={cc.name}>
                            {cc.name}
                          </MenuItem>
                        ))}
                      </StyledInput>
                    </div>
                  </Grid>
                  {availableProjectIds?.length > 0 && <Grid item xs={4}>
                    <div className={styles.dropdownWrap}>
                      <StyledInput
                        select
                        fullWidth
                        label={`Project Id`}
                        name={"projectId"}
                        variant="outlined"
                        onChange={(e: any) => {
                          setProjectId(e.target.value)
                        }}
                      >
                        {availableProjectIds.map((pid) => (
                          <MenuItem key={pid} value={pid}>
                            {pid}
                          </MenuItem>
                        ))}
                      </StyledInput>
                    </div>
                  </Grid>}
                </Grid>
              </Grid>
            }

            <Grid item container xs={12}>
              {(bookingQuestions && bookingQuestions?.length) ? <Grid item xs={12} style={{ margin: '40px 0 30px 0' }}>
                <Typography variant="h4" align="center" style={{ fontWeight: 700 }} >Additional Information</Typography>
              </Grid> : null}
              <Grid item xs={12}>
                {(bookingQuestions && bookingQuestions?.length) ? bookingQuestions.map((bookingQuestion, idx) => {
                  switch (bookingQuestion.answerType) {
                    case ANSWER_TYPES.CHECKBOX:
                      return (
                        <Field name={`preBookingQuestions[${idx}].answer`} validate={(value: any) => validateAnswer(value, bookingQuestion.required, 'TRUE')} >
                          {({ field, meta: { touched, error }, }: FieldProps) => (
                            <div className={`${styles.validation} ${(touched && Boolean(error)) ? styles.error : ''}`}>
                              <FormControlLabel
                                control={
                                  <ColoredCheckbox
                                    {...field}
                                    checked={field.value}
                                    size="small"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                                }
                                label={`${bookingQuestion.question} ${bookingQuestion.required ? "*" : ""}`}
                              />
                              {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{bookingQuestion.errorMessage}</Typography>}
                            </div>
                          )}
                        </Field>
                      )
                      break;

                    case ANSWER_TYPES.DROP_DOWN:
                      return (
                        <Field name={`preBookingQuestions[${idx}].answer`} validate={(value: any) => validateAnswer(value, bookingQuestion.required, "TEXT")}>
                          {({ field, meta: { touched, error }, }: FieldProps) => (
                            <>
                              <div className={`${styles.bookingQuestion} ${touched && (Boolean(error)) ? styles.error : ''}`}>
                                <div className={styles.rowFlex}>
                                  <div style={{ display: 'flex', flex: 1, paddingRight: 15 }}>
                                    <Typography>{`${bookingQuestion.question} ${bookingQuestion.required ? "*" : ""}`}</Typography>
                                  </div>
                                  <div className={styles.dropdownWrap}>
                                    <StyledInput
                                      select
                                      {...field}
                                      fullWidth
                                      variant="outlined"
                                      // input={<StyledInputBase />}
                                      error={touched && Boolean(error)}
                                    >
                                      {bookingQuestion.options && bookingQuestion.options.map((option) => (
                                        <MenuItem key={option} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </StyledInput>
                                  </div>
                                </div>
                                {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{bookingQuestion.errorMessage}</Typography>}
                              </div>
                            </>
                          )}
                        </Field>
                      )
                      break;

                    case ANSWER_TYPES.YES_NO:
                      return (

                        <Field name={`preBookingQuestions[${idx}].answer`} validate={(value: any) => validateAnswer(value, bookingQuestion.required, bookingQuestion.expectedAnswer)}>
                          {({ field, meta: { touched, error }, }: FieldProps) => (
                            <RadioGroup {...field} onClick={field.onBlur} >
                              <div className={`${styles.bookingQuestion} ${touched && (Boolean(error)) ? styles.error : ''}`}>
                                <div className={styles.rowFlex}>
                                  <div style={{ display: 'flex', flex: 1, paddingRight: 15 }}>
                                    <Typography>{`${bookingQuestion.question} ${bookingQuestion.required ? "*" : ""}`}</Typography>
                                  </div>
                                  <div className={styles.yesnoWrap}>
                                    <Radio value="YES" id={`yes_no_${idx}_yes`} style={{ display: 'none' }} />
                                    <label htmlFor={`yes_no_${idx}_yes`} className={`${styles.yesnoButton} ${styles.left} ${field.value === "YES" ? styles.active : ''}`}>Yes</label>
                                    <Radio value="NO" id={`yes_no_${idx}_no`} style={{ display: 'none' }} />
                                    <label htmlFor={`yes_no_${idx}_no`} className={`${styles.yesnoButton} ${styles.right} ${field.value === "NO" ? styles.active : ''}`}>No</label>
                                  </div>
                                </div>
                                {touched && Boolean(error) && <Typography variant="body1" style={{ color: '#D13135' }}>{bookingQuestion.errorMessage}</Typography>}
                              </div>
                            </RadioGroup>
                          )
                          }
                        </Field>
                      )
                      break;
                  }
                }) : null}
                {(bookingQuestions && bookingQuestions?.length) ? bookingQuestions.map((bookingQuestion, idx) => {
                  switch (bookingQuestion.answerType) {
                    case ANSWER_TYPES.TEXT:
                      return (
                        <Field name={`preBookingQuestions[${idx}].answer`} validate={(value: any) => validateAnswer(value, bookingQuestion.required, 'TEXT')}>
                          {({ field, meta: { touched, error }, }: FieldProps) => (
                            <>
                              <Typography variant="body1"> {`${bookingQuestion.question} ${bookingQuestion.required ? "*" : ""}`}</Typography>
                              <StyledInput
                                variant="outlined"
                                {...field}
                                multiline
                                fullWidth
                                error={touched && Boolean(error)}
                              />
                              <Typography variant="body1" style={{ color: '#D13135', padding: '5px 15px 5px 15px' }}>{touched && Boolean(error) && bookingQuestion.errorMessage}</Typography>
                            </>
                          )}
                        </Field>
                      )
                      break;
                  }
                }) : null}
                <Field name={`customerNote`} >
                  {({ field, meta: { touched, error }, }: FieldProps) => (
                    <>
                      <StyledInput
                        variant="outlined"
                        label={`${getLocalizedBookingSyntex(country)} note`}
                        {...field}
                        multiline
                        fullWidth
                        error={touched && Boolean(error)}
                        style={{ margin: '40px 0 30px 0' }}
                      />
                    </>
                  )}
                </Field>
                <Field name="agreeToTerms" validate={(value: any) => validateAnswer(value, true, 'TRUE')}>
                  {({ field, meta: { touched, error }, }: FieldProps) => (
                    <div className={`${(touched && Boolean(error)) ? styles.error : ''}`} style={{ marginTop: 40, marginLeft: 3 }}>
                      <FormControlLabel
                        control={
                          <ColoredCheckbox
                            {...field}
                            size="small"
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                        label="I agree with the terms and conditions"
                      />
                      <a rel="noreferrer" target="_blank" className={styles.footerUrl} href={website.termsAndConditionsURL}>
                        <div>
                          <Hidden smDown><span style={{ paddingRight: 5 }}>Terms and Conditions</span></Hidden>
                          <LaunchIcon style={{ fontSize: 16 }} />
                        </div>
                      </a>
                      {(touched && Boolean(error)) && <Typography variant="body1" style={{ color: '#D13135' }}>The terms and conditions must be accepted.</Typography>}
                    </div>
                  )}
                </Field>
                <div style={{ marginTop: 20 }}></div>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default SimpleCustomerView;