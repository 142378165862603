import {
  TableContainer,
  Table,
  TableHead,
  Typography,
  TableBody,
  createStyles,
  makeStyles,
  Grid,
  Fab,
  CircularProgress,
  Hidden,
  TableRow,
  Paper,
  TableCell,
  IconButton
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { ApolloError, useMutation } from "@apollo/client";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { BookingStatus, IAddress, IBooking, ICustomerDetails } from '../../../../reducers/bookings/types';
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { CustomerType } from '../../../../reducers/auth/types';
import { IAppState } from '../../../../store';
import { StyledTableCell, StyledTableRow } from '../../../StyledTable';
import { SearchForm } from '../SearchForm';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import { ADD_DRIVERS_TO_BOOKING, REMOVE_DRIVERS_FROM_BOOKING } from '../../../../graphql/bookings/addDriversToBookingMutation';
import { DATE_TYPE, formatGraphQLErrorMessage, getUploadedImageByKey, isValidUrl } from '../../../utils';
import { ConfirmationDialog } from '../../../ConfirmationDialog/ConfirmationDialog';
import AddNewDriver, { defaultValues } from '../../NewBooking/PersonalDetails/CustomerView/AddNewDriver';
import { ADD_NEW_DRIVERS } from '../../../../graphql/customers/createCustomerMutation';
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from '../../../../utils/localized.syntex';
import _ from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    tableCellBorder: {
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    summaryLeftMargin: {
      marginLeft: 5
    },
    tableCellPadding: {
      padding: 8
    },
    tableContainer: {
      border: "solid 1px rgba(224, 224, 224, 1)",
      marginTop: "1rem"
    }
  })
);
interface IDriverInformationProps {
  booking: IBooking;
}

export interface IDriverRow {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  license: {
    authority: string;
    licenseNumber: string;
    dateOfExpiry: string;
    images: (string | object | undefined)[];
  };
  phoneNumber: {
    phone: string;
    country: string;
  };
  location: IAddress;
  nationalInsuranceNumber?: string;
  profession?: string;
  verificationMethod?: string;
}
const remainder = 15 - (dayjs().minute() % 15);
const minDateTime = dayjs()
  .add(remainder, "minutes")
  .toDate();
minDateTime.setMilliseconds(0);
minDateTime.setSeconds(0);

export const DriversInformation: React.FC<IDriverInformationProps> = (props) => {

  const snackbar = useSnackBar();
  const classes = useStyles();
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const [formatedDrivers, setFormatedDrivers] = useState<IDriverRow[]>([]);
  const [booking, setBooking] = useState<IBooking>();
  const [isAddDriverClicked, setIsAddDriverClicked] = useState<boolean>(false);
  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmationDialogeOpen] = useState<boolean>(false);
  const [removedDriverId, setRemovedDriverId] = useState<string>();
  const [removeDriverCheck, setRemoveDriverCheck] = useState(false)
  const [removeInProgress, setRemoveInProgress] = useState<boolean>(false);
  const [addDriver, setAddDriver] = useState<boolean>(false);
  const [removeDriverIndex, setRemoveDriverIndex] = useState(-1);
  const [newlyAddedDrivers, setNewlyAddedDrivers] = useState<IDriverRow[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<IDriverRow | null>(null);

  useEffect(() => {
    if (props && props.booking) {
      setBooking(props.booking)
    }
  }, [props.booking])

  const [addDriversToBooking, { loading: driversLoading }] = useMutation(ADD_DRIVERS_TO_BOOKING, {
    onCompleted: (data) => {
      if (data && data.consumerAddDriversToBooking && data.consumerAddDriversToBooking.approvedDrivers) {
        _setDrivers([...data.consumerAddDriversToBooking.approvedDrivers])
      }
      setUpdateInProgress(false);
      setIsAddDriverClicked(false)
      setNewlyAddedDrivers([])
      snackbar({
        message: "Driver saved successfully",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [removeDriverFromBooking] = useMutation(REMOVE_DRIVERS_FROM_BOOKING, {
    onCompleted: (data) => {
      if (data && data.removeDriverFromBooking) {
        setRemoveDriverCheck(false)
        _setDrivers([...data.removeDriverFromBooking.approvedDrivers])
        snackbar({
          message: "Driver removed successfully",
          variant: SnackBarVariant.SUCCESS
        });
        setIsConfirmationDialogeOpen(false);
        setRemoveInProgress(false);
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
      setIsConfirmationDialogeOpen(false);
      setRemoveInProgress(false);
    }
  });

  const [addNewDrivers, { loading: driverLoading, data: newDriverData }] = useMutation(ADD_NEW_DRIVERS, {
    onCompleted: (data) => {
      const newDriversId: string[] = [];
      if (data && data.consumerAddDrivers) {
        data.consumerAddDrivers.forEach((driver: IDriverRow) => {
          if (driver && driver.id) {
            newDriversId.push(driver.id);
          }
        });

        if (newDriversId && newDriversId.length) {
          addDriversToBooking({
            variables: {
              bookingId: booking?.id,
              drivers: newDriversId
            }
          })
        } else {
          snackbar({
            message: "Driver is already added!",
            variant: SnackBarVariant.ERROR
          });
        }
      }
    },
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  })

  useEffect(() => {
    if (props.booking.customer && user?.customer) {
      const data = user.customer;
      if (props.booking && props.booking.customerType === CustomerType.INDIVIDUAL && data) {
        _setDrivers([...props.booking.approvedDrivers, {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          license: {
            authority: data.license?.authority,
            dateOfExpiry: data.license?.dateOfExpiry,
            licenseNumber: data.license?.licenseNumber,
            images: data.license?.images
          },
          phoneNumber: data.phoneNumber,
        }])
      }
    }
    if (props.booking && props.booking.customerType === CustomerType.BUSINESS) {
      _setDrivers(props.booking.approvedDrivers);
    }
  }, [props.booking, user]);

  const _setDrivers = (drivers: IDriverRow[]) => {
    const promises: Promise<IDriverRow>[] = drivers.map(async (driver: any, index: number) => {
      try {
        if (driver && driver.license && driver.license.images && driver.license.images.length) {
          const updatedImages = await Promise.all(
            driver.license.images.map(async (image: string) => {
              try {
                const imageData = await isValidUrl(image)
                  ? image
                  : await getUploadedImageByKey(image);
                return imageData;
              } catch (error) {
                throw error;
              }
            })
          );

          return {
            ...driver,
            license: {
              ...driver.license,
              images: updatedImages,
            },
          };
        }

        return driver;
      } catch (error) {
        throw error;
      }
    });

    Promise.all(promises)
      .then((updatedDrivers) => {
        if (removedDriverId) {
          const filteredDrivers = formatedDrivers.filter((driver) => driver.id !== removedDriverId);
          setFormatedDrivers(filteredDrivers);
          setRemovedDriverId("");
        } else {
          const combinedArray = [...formatedDrivers, ...updatedDrivers];
          const uniqueData = combinedArray.filter((item, index, self) => {
            return index === self.findIndex((el) => el.id === item.id);
          });
          setFormatedDrivers(uniqueData);
        }
      })
      .catch((error) => {
        throw error;
      });
  };



  const addExistingDriver = (data: IDriverRow) => {
    const existingDriver = formatedDrivers.find((driver: IDriverRow) => driver.id === data.id);
    if (existingDriver) {
      return snackbar({
        message: "Selected driver already added.",
        variant: SnackBarVariant.ERROR
      });
    }
    if (data && data.id) {
      addExistingDriverHandler(data);
    }
  };

  const addExistingDriverHandler = (data: IDriverRow) => {
    setUpdateInProgress(true);
    addDriversToBooking({
      variables: {
        bookingId: booking?.id,
        drivers: [data?.id]
      }
    })
  }

  const removeDriver = () => {
    setRemoveInProgress(true);
    if (removedDriverId) {
      setRemoveDriverCheck(true)
      removeDriverFromBooking({
        variables: {
          bookingId: props.booking.id,
          driverId: removedDriverId
        }
      })
    }
  };

  const handleEditDriver = (newDriver: IDriverRow) => {
    const restDrivers = newlyAddedDrivers.filter((driver) => driver.email !== newDriver.email)
    const { verificationMethod, ...rest } = newDriver
    setSelectedDriver(rest);
    setAddDriver(true)
    setNewlyAddedDrivers(restDrivers)
  };

  const handleRemove = (email: string) => {
    const updatedDrivers = newlyAddedDrivers.filter((driver) => driver.email !== email)
    setNewlyAddedDrivers(updatedDrivers)
  }

  const addAllDrivers = () => {
    if (newlyAddedDrivers.length) {
      setAddDriver(false)
      if (booking && booking.customer?.id) {
        addNewDrivers({
          variables: {
            customerId: booking?.customer?.id,
            drivers: newlyAddedDrivers
          }
        });
      }
    }
  }

  const handleSubmit = (newDriver: IDriverRow) => {
    const existingDriver = newlyAddedDrivers.some((driver) => driver.email === newDriver.email)
    const existingDriverInFormated = formatedDrivers.some((formattedDriver) => formattedDriver.email === newDriver.email);
    if (existingDriver || existingDriverInFormated) {
      return snackbar({
        message: "Selected driver already added.",
        variant: SnackBarVariant.ERROR
      });
    }
    setSelectedDriver(null)
    setAddDriver(false)
    setNewlyAddedDrivers((prevDrivers) => [...prevDrivers, newDriver])
  }

  return (
    <>
      <Grid container justify='space-between'>
        <Grid item>
          <div className="font-medium semi-bold margin-bottom padding-bottom">Driver Information </div>
        </Grid>
        <Grid item>
            {props.booking &&
              (props.booking.status === BookingStatus.CONFIRMED || props.booking.status === BookingStatus.IN_PROGRESS) &&
              <Grid container item justify="flex-end">
                <Fab
                  variant="extended"
                  size="small"
                  aria-label="Add New"
                  onClick={() => setIsAddDriverClicked(true)}
                >
                  Add New
                </Fab>
              </Grid>}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {isAddDriverClicked && (
          <>
            <Grid container xs={12} style={{ marginTop: 10, alignItems: "center" }}>
              {user?.customerId && <><Grid item xs={6} md={6} style={{ maxWidth: "fit-content" }}>
                <Typography
                  style={{ cursor: "pointer" }}
                  variant={"subtitle1"}
                  onClick={() => {
                    setAddDriver(true)
                    setSelectedDriver(_.cloneDeep(defaultValues))
                  }}
                >
                  + Add New Driver
                </Typography>
              </Grid>
                <Grid item xs={6} md={6} style={{ display: "flex", marginLeft: "auto" }} alignItems="center" justify="flex-end">
                  <SearchForm
                    updateData={addExistingDriver}
                    dataOptions={user?.customerId ? user.customer?.approvedDrivers : user?.businesscustomers?.approvedDrivers}
                    disabled={updateInProgress}
                  />
                  <Typography
                    variant={"subtitle1"}
                    style={{ cursor: "pointer" }}
                  >
                    {updateInProgress && <CircularProgress size={16} style={{ color: "white", marginRight: "10px" }} />}
                  </Typography>
                </Grid></>}
              {user?.businesscustomers?.id &&
                <Grid item xs={6} md={6}>
                  <SearchForm
                    updateData={addExistingDriver}
                    dataOptions={user?.businesscustomers?.approvedDrivers}
                    disabled={updateInProgress}
                  />
                  <Typography
                    variant={"subtitle1"}
                    style={{ cursor: "pointer" }}
                  >
                    {updateInProgress && <CircularProgress size={16} style={{ color: "white", marginRight: "10px" }} />}
                  </Typography>
                </Grid>}
            </Grid>
            {addDriver && (
              <Grid item container xs={12}>
                <AddNewDriver
                  saveDriverLoading={false}
                  handleSubmit={handleSubmit}
                  openForm={() => setAddDriver(false)}
                  selectedDriver={selectedDriver}
                />
              </Grid>
            )}
            {newlyAddedDrivers.length ? (<>
              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h4">Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">Email</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h4">Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newlyAddedDrivers.map((driver: IDriverRow) => (
                      <TableRow key={driver.id}>
                        <TableCell>{`${driver.firstName} ${driver.lastName}`}</TableCell>
                        <TableCell>{driver.email}</TableCell>
                        <TableCell>
                          {!driver.id && (
                            <IconButton
                              color="primary"
                              aria-label="edit"
                              onClick={() => handleEditDriver(driver)}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            color="secondary"
                            aria-label="delete"
                            onClick={() => handleRemove(driver.email)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item container xs={12}>
                <Fab
                  variant="extended"
                  size="medium"
                  style={{ margin: 10 }}
                  disabled={driversLoading || driverLoading}
                  onClick={() => {
                    addAllDrivers()
                  }}
                >{(driversLoading || driverLoading) && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                  Save Drivers
                </Fab>
              </Grid>
            </>) : <></>}
          </>
        )}

        {formatedDrivers && formatedDrivers.length > 0 ? (
          <>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <TableContainer>
                  <Table stickyHeader aria-label="customized table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Licence</StyledTableCell>
                        <StyledTableCell>Licence Expiry</StyledTableCell>
                        <StyledTableCell>Authority</StyledTableCell>
                        <StyledTableCell>Phone</StyledTableCell>
                        <StyledTableCell>Licence Image</StyledTableCell>
                        <StyledTableCell> </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {formatedDrivers && formatedDrivers.length && formatedDrivers.map(
                        (item: IDriverRow, index: number) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell> {item.firstName} {item.lastName} </StyledTableCell>
                              <StyledTableCell>{item.email}</StyledTableCell>
                              <StyledTableCell> {item.license && item.license.licenseNumber ? item.license.licenseNumber : ""} </StyledTableCell>
                              <StyledTableCell> {item.license && item.license.dateOfExpiry ? getLocalizedDateFormat(country, item.license.dateOfExpiry, DATE_TYPE.CONDENSED) : ""} </StyledTableCell>
                              <StyledTableCell> {item.license && item.license.authority ? item.license.authority : ""}</StyledTableCell>
                              <StyledTableCell> {item.phoneNumber && item.phoneNumber.phone ? item.phoneNumber.phone : ""} </StyledTableCell>
                              <StyledTableCell>
                                {item.license &&
                                  item.license.images.length ?
                                  item.license.images.map(
                                    (image: any, index: number) => {
                                      return (
                                        <a
                                          href={image}
                                          target="_blank"
                                          key={index}
                                        >
                                          <img
                                            src={image}
                                            width="40"
                                            height="30"
                                          />
                                        </a>
                                      );
                                    }
                                  ) : ""
                                } </StyledTableCell>
                              <StyledTableCell>
                                {removeDriverCheck && removeDriverIndex === index ? (
                                  <CircularProgress size={16} style={{ color: "white", marginRight: "10px" }} />
                                ) : (
                                  <Typography variant={"subtitle1"} style={{ cursor: "pointer" }}>
                                    <button
                                      style={{
                                        border: "none",
                                        background: "none",
                                        padding: 0,
                                        fontSize: "16px"
                                      }}
                                      disabled={booking && booking.customer?.id === item.id}
                                      onClick={() => {
                                        setIsConfirmationDialogeOpen(true);
                                        if (item && item.id) {
                                          setRemovedDriverId(item.id);
                                          setRemoveDriverIndex(index); // Store the index of the driver to remove
                                        }
                                      }}
                                    >
                                      X
                                    </button>
                                  </Typography>
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {isConfirmDialogOpen && (
                <ConfirmationDialog
                  isOpen={isConfirmDialogOpen}
                  onCancel={() => setIsConfirmationDialogeOpen(false)}
                  onConfirm={removeDriver}
                  description={`Are you sure you want to remove this driver from the ${getLocalizedBookingSyntex(country).toLowerCase()}?`}
                  title={""}
                  isLoading={removeInProgress}
                />
              )}
          </>
        ) : (
          <p> Driver not found</p>
        )}

      </Grid>
    </>
  )
}
