import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import styles from "./index.module.css";
import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Tooltip,
  IconButton,
  TextField
} from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import Grid from "@material-ui/core/Grid";
import {
  IBooking,
  IBookingVehicleGroup,
  IVehiclePriceGroup,
  IAddonRequirement,
  ILocationSurchargeRules,
  PriceRuleOperator,
  ValueType,
  BILLING_CYCLE_NAME,
  BookingStatus,
} from "../../../../reducers/bookings/types";
import { StyledTableCell, StyledTableRow } from "../../../StyledTable";
import {
  DATE_TYPE,
  capitalize,
  formatGraphQLErrorMessage,
  getRetnalTaxAmount,
  getTaxDescription,
  isLongTermBooking,
  rateTypeName,
  toCurrency,
} from "../../../utils";
import { DateTime as d, Settings } from "luxon";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { RATE_TYPES } from "../../utils";
import dayjs from "dayjs";
import { Storage } from "aws-amplify";
import { DepositCollection } from "../../../consts";
import { getLocalizedBookingSyntex, getLocalizedDateFormat, getLocalizedTaxSyntex } from "../../../../utils/localized.syntex";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from "apollo-boost";
import { ADD_BOOKING_PO_NUMBER } from "../../../../graphql/bookings/addBookingPoNumberMutation";

export interface IProps {
  booking: IBooking;
}

const Quote: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const [booking, setBooking] = useState<IBooking>();
  const [weeklyTaxAmount, setWeekyTaxAmount] = useState<number>(0);
  const [bookingDurationInDays, setBookingDurationInDays] = useState<number>(0);
  const [isProrated, setIsProrated] = useState<boolean>(false);
  const [proratedFraction, setProratedFraction] = useState<number>(1);
  const [fixedRateAddonAmount, setFixedRateAddonAmount] = useState<number>(0);
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const [collectDeposit, setCollectDeposit] = useState<boolean>(false);
  const branchData = website.branches;
  const { currency, locale } = website.organisation;
  const { country } = website.organisation.address;
  const [logoUrl, setLogoUrl] = useState<any>("");
  const [poNumber, setPoNumber] = useState<string>("");
  const [isEditablePONumber, setIsEditablePONumber] = useState<boolean>(false);
  const [weeklyRentalAmount, setWeeklyRentalAmount] = useState<number>(0);
  const [weeklyAddonAmount, setWeeklyAddonAmount] = useState<number>(0);
  const [weeklyInsuranceAmount, setWeeklyInsuranceAmount] = useState<number>(0);
  const [weeklySubscription, setWeeklySubscription] = useState<boolean>(false);

  const [addBookingPoNumber] = useMutation(ADD_BOOKING_PO_NUMBER, {
    onCompleted: (data) => {
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} purchase order number updated successfully`,
        variant: SnackBarVariant.SUCCESS
      })
      if (booking) {
        setBooking({
          ...booking,
          poNumber: data.addBookingPoNumber
        })
      }
      setPoNumber(data.addBookingPoNumber)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  const addPoNumberToBooking = (_poNumber: string) => {
    if (booking) {
      addBookingPoNumber({
        variables: {
          bookingId: booking.id,
          poNumber: _poNumber
        }
      })
    }
  }
  const getApplicableRentalAmount = (
    baseRate: number,
    operator?: string,
    valueType?: ValueType,
    value?: number
  ) => {
    if (operator && valueType && value) {
      switch (operator) {
        case PriceRuleOperator.SURGE:
          if (valueType === ValueType.VARIABLE) {
            return baseRate + Math.round((baseRate * value) / 100);
          }
          return baseRate + value;
        case PriceRuleOperator.DISCOUNT:
          let amount = 0;
          if (valueType === ValueType.VARIABLE) {
            amount = baseRate - Math.round((baseRate * value) / 100);
          } else {
            amount = baseRate - value;
          }
          return amount > 0 ? amount : 0;
      }
    }
    return baseRate;
  };

  useEffect(() => {
    if (props.booking) {
      const _bookingData: IBooking = props.booking;
      let payableAddonAmount = 0;
      let taxAmount = 0;
      let weeklyRentalAmountRentalAmount = 0;
      let weeklyInsuranceAmount = 0;
      let weeklyAddonAmount = 0
      if (_bookingData.addonRequirements && _bookingData.addonRequirements.length) {
        for (let i = 0; i < _bookingData.addonRequirements.length; i++) {
          const addonR = _bookingData.addonRequirements[i];
          if (!addonR.hasFixedRate) {
            weeklyAddonAmount += addonR.rate * addonR.quantity * _bookingData.paymentDetails.rentalTime
            taxAmount +=
              addonR.tax && addonR.tax.value
                ? ((addonR.rate * addonR.tax.value) / 100) *
                addonR.quantity *
                _bookingData.paymentDetails.rentalTime
                : 0;
          }
        }
      }
      setIsProrated(_bookingData.paymentDetails.isProrated ? true : false)
      let fraction = 1;
      if (_bookingData.paymentDetails.isProrated && _bookingData.paymentDetails.proratedBillingDays && _bookingData.paymentDetails.standardBillingDays) {
        fraction = parseFloat((_bookingData.paymentDetails.proratedBillingDays / _bookingData.paymentDetails.standardBillingDays).toString())
        setProratedFraction(fraction)
      }
      let bookingDuration = 0;
      const longTermBooking = isLongTermBooking(_bookingData);
      if (longTermBooking) {
        switch (_bookingData.rateTypeName) {
          case BILLING_CYCLE_NAME.DAILY:
            if (_bookingData.isRecurringBilling && _bookingData.billingFrequency) {
              bookingDuration = _bookingData.billingFrequency;
              setBookingDurationInDays(bookingDuration);
            }
            break;
          case BILLING_CYCLE_NAME.WEEKLY:
            if (_bookingData.isRecurringBilling && _bookingData.billingFrequency) {
              bookingDuration = 7 * _bookingData.billingFrequency
            } else {
              bookingDuration = 7
            }
            setBookingDurationInDays(bookingDuration);
            break;
          case BILLING_CYCLE_NAME.MONTHLY:
            if (_bookingData.isRecurringBilling && _bookingData.billingFrequency) {
              let idealEndDate = d.fromISO(_bookingData.pickupDateTime || "").plus({ months: _bookingData.billingFrequency }).toUTC().toISO();
              bookingDuration = d.fromISO(idealEndDate).diff(d.fromISO(_bookingData.pickupDateTime), "day").days;
              setBookingDurationInDays(bookingDuration);
            }
            break;
          default:
            break;
        }
      } else {
        bookingDuration = Math.ceil(d.fromISO(_bookingData.dropoffDateTime || "").diff(d.fromISO(_bookingData.pickupDateTime || ""), ["days"]).days);
      }
      if (_bookingData.isSubscription && _bookingData.activeSubscription) {
        const flag = _bookingData.activeSubscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? true : false
        setWeeklySubscription(flag)
      }
      const selectedBranch = branchData.find(
        (branch) => branch.id === _bookingData.branchId
      );
      if (selectedBranch) {
        Settings.defaultZone = selectedBranch.timeZone || "Europe/London";
      }
      if (_bookingData && _bookingData.vehicleGroups.length) {
        for (let j = 0; j < _bookingData.vehicleGroups.length; j++) {
          const vehicleGroup = _bookingData.vehicleGroups[j]
          let rentalAmount = getApplicableRentalAmount(
            vehicleGroup.baseRate,
            vehicleGroup.priceRuleOperator,
            vehicleGroup.priceRuleValueType,
            vehicleGroup.priceRuleAmount
          ) *
            vehicleGroup.count *
            _bookingData.paymentDetails.rentalTime;
          weeklyRentalAmountRentalAmount += rentalAmount
          const rentalTaxAmount = getRetnalTaxAmount(
            _bookingData.tax || [],
            rentalAmount,
            bookingDuration,
            vehicleGroup.count
          );
          taxAmount += rentalTaxAmount
        }
      }
      const totalVehicles = _bookingData.vehicleGroups.reduce(
        (accumulator, current) => accumulator + current.count,
        0
      );
      if (_bookingData.insurancePolicy && _bookingData.insuranceRate) {
        weeklyInsuranceAmount = _bookingData?.insuranceRate *
          totalVehicles *
          (_bookingData.paymentDetails?.rentalTime || 1)
      }
      setWeeklyAddonAmount(weeklyAddonAmount)
      setWeeklyRentalAmount(weeklyRentalAmountRentalAmount)
      setBookingDurationInDays(bookingDuration);
      setFixedRateAddonAmount(payableAddonAmount);
      setWeekyTaxAmount(taxAmount);
      setWeeklyInsuranceAmount(weeklyInsuranceAmount);
      setBooking(_bookingData);
      setPoNumber(props.booking.poNumber || "")
    }
  }, [props.booking]);

  useEffect(() => {
    if (website.organisation && website.organisation.logoUrl) {
      getLogoUpload(website.organisation.logoUrl)
    }
  }, [website.organisation]);

  useEffect(() => {
    if (website && booking) {
      const selectedbranch = website.branches.find(branch => branch.id === booking.branchId);
      if (selectedbranch?.depositCollection === DepositCollection.AT_BOOKING_CONFIRMATION) {
        setCollectDeposit(true);
      }
    }
  }, [website, booking])

  const getLogoUpload = async (logoUrl: string) => {
    const config = { contentType: "image/*", level: "public" };
    const file = await Storage.get(logoUrl, config).then((result) => {
      return result;
    }).catch((err) => {
    });
    const response = await fetch(file as string).then((res) => {
      return res;
    });
    if (!response.ok) {
      setLogoUrl("");
      return;
    }
    setLogoUrl(file);

  };

  if (!booking) {
    return <CircularProgress size={20} thickness={5} />;
  }

  const isBusinessCustomerBooking =
    booking.customerType === "business" &&
      booking.businessCustomer &&
      booking.businessCustomer.id !== ""
      ? true
      : false;

  const getRateTypeString = () => {
    const rateType = booking?.rateTypeName;
    if (rateType) {
      switch (rateType) {
        case "hourly":
          return "Hour (s)";
        case "daily":
          return "Day (s)";
        case "weekly":
          return "Week (s)";
        case "monthly":
          return "Month (s)";
        default:
          return "Day (s)";
      }
    }
  };

  const getAddonRate = (addon: IAddonRequirement, rentalTime: number) => {
    let rate = addon.rate * addon.quantity;
    if (!addon.hasFixedRate && rentalTime) {
      rate = rate * rentalTime * proratedFraction;
    }
    return rate;
  };

  const address = `${website.organisation!.address.street}, ${website.organisation!.address.city
    }, ${website.organisation!.address.zipcode}`;

    let COLUMN_NAMES = [
      "ITEM",
      "DESCRIPTION",
      "QUANTITY"
    ];
  
    if (website.organisation.closeGroupSharingEnabled) {
      COLUMN_NAMES = [...COLUMN_NAMES, "DURATION"];
    } else {
      COLUMN_NAMES = [...COLUMN_NAMES, 
      "UNIT PRICE",
      "DURATION",
      "VAT/TAX",
      "PRICE",
  ];
    } 

  return (
    <Grid container>
      <Grid item xs={12} style={{ display: "flex" }}>
        <div
          className={styles.card}
          style={{ marginRight: 0, marginBottom: -15 }}
        >
          <div className="flex">
            <div className={styles.logo}>
              {logoUrl && <img src={logoUrl || ""} alt="logo" />}
            </div>
            <div className="flex fill col-flex cross-end">
              <Typography variant={"h4"} align="right">
                {website.organisation.name}
              </Typography>
              <Typography variant={"body1"} align="right">
                {address}
              </Typography>
              <Typography variant="body1" align="right">
                Tel. No.: {website.organisation.phoneNumber.phone} {"|"} Email:{" "}
                {website.organisation.emailId}
              </Typography>
              <Typography variant="body1" align="right">
                VAT No.: {website.organisation.orgVatNumber} {"|"} Co Reg. No.:{" "}
                {website.organisation.orgRegNumber}
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4} style={{ display: "flex" }}>
        <div className={styles.card}>
          <div className="margin-bottom">
            <Typography
              variant="body1"
              className="opacity60 padding-bottom--small"
            >
              Customer:
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {`${isBusinessCustomerBooking
                ? booking.businessCustomer?.businessName || ""
                : `${booking.customer?.firstName} ${booking.customer?.lastName}`
                }`}
            </Typography>
          </div>
          <div className="margin-bottom">
            <Typography
              variant="body1"
              className="opacity60 padding-bottom--small"
            >
              Email Address:
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {`${isBusinessCustomerBooking
                ? booking.businessCustomer?.billing.email
                : booking.customer
                  ? booking.customer.email
                  : ""
                }`}
            </Typography>
          </div>
          <div>
            <Typography
              variant="body1"
              className="opacity60 padding-bottom--small"
            >
              Phone Number:
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {`${isBusinessCustomerBooking
                ? booking.businessCustomer?.billing.phoneNumber.phone || ""
                : booking.customer
                  ? booking.customer.phoneNumber.phone
                  : ""
                }`}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={3} style={{ display: "flex" }}>
        <div className={styles.card} style={{ justifyContent: "flex-start" }}>
          <div className="margin-bottom">
            <Typography
              variant="body1"
              className="opacity60 padding-bottom--small"
            >
              {`${getLocalizedBookingSyntex(country)} Reference:`}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {booking.referenceNumber}
            </Typography>
          </div>
          <div>
            <Typography
              variant="body1"
              className="opacity60 padding-bottom--small"
            >
              Date Created:
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {getLocalizedDateFormat(country, booking.dateCreated, DATE_TYPE.EXPANDED)}
            </Typography>
          </div>
          {isBusinessCustomerBooking && <div className="margin-top">
            {isEditablePONumber ? (<Grid item xs={10} container className={"po-number-main"}>
              <TextField
                label="PO Number"
                id="po-number"
                fullWidth
                value={poNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPoNumber(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {
                        <Tooltip title="Save" style={{ padding: 0 }}>
                          <IconButton
                            aria-label="toggle po number "
                            onClick={() => {
                              addPoNumberToBooking(poNumber)
                              setIsEditablePONumber(false)
                            }}
                          >
                            <DoneIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      <Tooltip title="Close" style={{ padding: 0 }}>
                        <IconButton
                          aria-label="toggle po number"
                          onClick={() => {
                            setPoNumber(booking?.poNumber || "")
                            setIsEditablePONumber(false)
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                }}
              />
            </Grid>) : (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  variant="body1"
                  className="opacity60 padding-bottom--small"
                >
                  PO Number:
                </Typography>
                <Typography variant="body1" className="semi-bold">
                  {poNumber || "N/A"}
                </Typography>
                <Typography variant="body1" className="semi-bold">
                  <Tooltip title={poNumber ? "Edit" : "Click to add"} style={{ padding: 0 }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      disabled={booking.status === BookingStatus.DRAFT ? true : false}
                      onClick={() => setIsEditablePONumber(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </div>
            )}
          </div>}
        </div>
      </Grid>
      <Grid item xs={12} md={5} style={{ display: "flex" }}>
        <div className={styles.card} style={{ marginRight: 0 }}>
          <div>
            <Typography
              variant="body1"
              className="opacity60 padding-bottom--small"
            >
              {booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                ? "Delivery Address, Date & Time:"
                : "Pick-up Location, Date & Time:"}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {`${booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                ? booking.pickupOtherLocation.fullAddress
                : booking.pickupServiceLocation?.name || "N/A"
                }`}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {
                getLocalizedDateFormat(country, booking.pickupDateTime, DATE_TYPE.EXPANDED, booking.branch?.timeZone)
              }
            </Typography>
          </div>
          <div>
            <Typography
              variant="body1"
              className="opacity60 padding-bottom--small"
            >
              {booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                ? "Collection Address, Date & Time:"
                : "Drop-off Location, Date & Time:"}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {`${booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                ? booking.dropoffOtherLocation.fullAddress
                : booking.dropoffServiceLocation?.name || "N/A"
                }`}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {booking.dropoffDateTime && getLocalizedDateFormat(country, booking.dropoffDateTime, DATE_TYPE.EXPANDED, booking.branch?.timeZone)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <div className={`${styles.box}`}>
          <div style={{ margin: "0 -10px -10px -10px" }}>
            <TableContainer
              style={{ maxHeight: "100%" }}
              className="no-row-hover"
            >
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    {COLUMN_NAMES.map((name, index) => (
                      <StyledTableCell key={index}>{name === "VAT/TAX" ? getLocalizedTaxSyntex(country) : name}</StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {
                    booking.isSubscription ? (
                      <>
                        <StyledTableRow>
                          <StyledTableCell
                            rowSpan={booking?.activeSubscription?.enrollmentAmount > 0 ? 2 : 1}
                          >
                            <Typography variant="body1">
                              {"Subscription"}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            {`${weeklySubscription ? "Weekly" : "Monthly"} Subscription Charges`} <br />
                            {booking?.activeSubscription?.subscription?.name} <br />
                            {getTaxDescription(booking.tax || [], currency, locale)?.map(title => {
                              return (
                                <>
                                  <span>({title})</span><br></br>
                                </>
                              )
                            })}
                          </StyledTableCell>
                          <StyledTableCell>{1}</StyledTableCell>
                          <StyledTableCell>
                            {
                              toCurrency(booking?.activeSubscription?.subscriptionAmount || 0, currency, locale)
                            }
                          </StyledTableCell>
                          <StyledTableCell>{weeklySubscription ? "1 Week" : "1 Month"}</StyledTableCell>
                          <StyledTableCell>
                            {toCurrency((booking.tax ? Math.round(getRetnalTaxAmount(booking.tax, booking?.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1)) : 0), currency, locale)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {toCurrency((booking?.activeSubscription?.subscriptionAmount || 0) + (booking.tax ? Math.round(getRetnalTaxAmount(booking.tax, booking?.activeSubscription?.subscriptionAmount || 0, bookingDurationInDays, 1)) : 0), currency, locale)}
                          </StyledTableCell>
                        </StyledTableRow>
                        {
                          booking?.activeSubscription?.enrollmentAmount > 0 && (
                            <StyledTableRow>
                              <StyledTableCell>
                                {"Enrollment Fees"} <br />
                                {booking?.activeSubscription?.subscription?.name} <br />
                              </StyledTableCell>
                              <StyledTableCell>{1}</StyledTableCell>
                              <StyledTableCell>
                                {
                                  toCurrency(booking?.activeSubscription?.enrollmentAmount || 0, currency, locale)
                                }
                              </StyledTableCell>
                              <StyledTableCell>N/A</StyledTableCell>
                              <StyledTableCell>
                                {toCurrency(Math.round(0), currency, locale)}
                              </StyledTableCell>
                              <StyledTableCell>
                                {
                                  toCurrency(booking?.activeSubscription?.enrollmentAmount || 0, currency, locale)
                                }
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        }
                        {(() => {
                          if (booking.insurancePolicy?.id) {
                            const totalVehicles = 1
                            return (
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography variant="body1">
                                    {"Insurance"}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {booking?.insurancePolicy?.name}
                                </StyledTableCell>
                                <StyledTableCell>{totalVehicles}</StyledTableCell>
                                <StyledTableCell>
                                  {toCurrency(
                                    booking?.insuranceRate,
                                    currency,
                                    locale
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {booking.paymentDetails?.rentalTime}{" "}
                                  {getRateTypeString()}
                                </StyledTableCell>
                                <StyledTableCell>N/A</StyledTableCell>
                                <StyledTableCell>
                                  {toCurrency(
                                    booking?.insuranceRate *
                                    1 * (booking.paymentDetails?.rentalTime || 1),
                                    currency,
                                    locale
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          }
                        })()}
                        {booking?.addonRequirements?.map(
                          (addonR: IAddonRequirement, idx: number) => {
                            let taxAmount = 0;
                            let taxDescription = "";
                            if (addonR.hasFixedRate) {
                              taxAmount =
                                addonR.tax && addonR.tax.value
                                  ? ((addonR.rate * addonR.tax.value) / 100) *
                                  addonR.quantity
                                  : 0;
                            } else {
                              taxAmount =
                                addonR.tax && addonR.tax.value
                                  ? ((addonR.rate * addonR.tax.value) / 100) *
                                  addonR.quantity *
                                  booking.paymentDetails.rentalTime
                                  : 0;
                            }
                            if (addonR.tax && addonR.tax.value && addonR.tax.title) {
                              taxDescription = `(${addonR.tax.title} ${addonR.tax.value}%)`;
                            }
                            return (
                              <>
                                <StyledTableRow>
                                  {idx === 0 && (
                                    <StyledTableCell
                                      rowSpan={booking.addonRequirements.length}
                                    >
                                      <Typography variant="body1">
                                        {"Add-Ons"}
                                      </Typography>
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell colSpan={1}>
                                    {addonR.addon.displayName
                                      ? addonR.addon.displayName
                                      : addonR.addon.name.toUpperCase()}{" "}
                                    <br />
                                    {taxDescription}
                                  </StyledTableCell>
                                  <StyledTableCell>{addonR.quantity}</StyledTableCell>
                                  <StyledTableCell>
                                    {toCurrency(addonR.rate, currency, locale)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {addonR.hasFixedRate
                                      ? "N/A"
                                      : `${booking.paymentDetails?.rentalTime
                                      } ${getRateTypeString()}`}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {toCurrency(taxAmount, currency, locale)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {toCurrency(
                                      getAddonRate(
                                        addonR,
                                        booking.paymentDetails?.rentalTime
                                      ) + taxAmount,
                                      currency,
                                      locale
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            );
                          }
                        )}
                        <StyledTableRow >
                          <StyledTableCell colSpan={5}></StyledTableCell>
                          <StyledTableCell
                            style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}
                          >
                            <Typography variant="h4">
                              Grand Total
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell >
                            {toCurrency(
                              booking?.paymentDetails?.totalPayableAmount,
                              currency,
                              locale
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                        {booking.discount.amount > 0 && (
                          <StyledTableRow>
                            <StyledTableCell colSpan={5}></StyledTableCell>
                            <StyledTableCell
                              style={{
                                borderLeft: "solid 1px rgba(224, 224, 224, 1)",
                              }}
                            >
                              <Typography variant="h4">{"DISCOUNT"}</Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                              -{" "}
                              {toCurrency(booking.paymentDetails.discountAmount, currency, locale)}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {booking?.vehicleGroups?.map(
                          (vehicleGroup: IBookingVehicleGroup, idx: number) => {
                            const vg =
                              vehicleGroup.vehicleGroup as IVehiclePriceGroup;
                            let rentalAmount =
                              getApplicableRentalAmount(
                                vehicleGroup.baseRate,
                                vehicleGroup.priceRuleOperator,
                                vehicleGroup.priceRuleValueType,
                                vehicleGroup.priceRuleAmount
                              ) *
                              vehicleGroup.count *
                              booking.paymentDetails.rentalTime;
                            rentalAmount = isProrated ? rentalAmount * proratedFraction : rentalAmount
                            const rentalTaxAmount = getRetnalTaxAmount(
                              booking.tax || [],
                              rentalAmount,
                              bookingDurationInDays,
                              vehicleGroup.count
                            );
                            const taxTitles = getTaxDescription(
                              booking.tax || [],
                              currency,
                              locale
                            );
                            return (
                              <>
                                <StyledTableRow>
                                  {idx === 0 && (
                                    <StyledTableCell
                                      rowSpan={booking?.vehicleGroups.length}
                                    >
                                      <Typography variant="body1">
                                        {"Rental Price"}
                                      </Typography>
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell colSpan={1}>
                                    {vg.name} <br />
                                    {!website.organisation.closeGroupSharingEnabled && <>
                                    {taxTitles.length > 0 &&
                                      taxTitles.map((title) => {
                                        return (
                                          <>
                                            <span>({title})</span>
                                            <br></br>
                                          </>
                                        );
                                      })}
                                      </>
                                    }
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {vehicleGroup.count}
                                  </StyledTableCell>
                                  {!website.organisation.closeGroupSharingEnabled &&
                                  <StyledTableCell>
                                    {toCurrency(
                                      getApplicableRentalAmount(
                                        vehicleGroup.baseRate,
                                        vehicleGroup.priceRuleOperator,
                                        vehicleGroup.priceRuleValueType,
                                        vehicleGroup.priceRuleAmount
                                      ),
                                      currency,
                                      locale
                                    )}
                                  </StyledTableCell>
                                  }
                                  <StyledTableCell>
                                    {isProrated ?
                                      `${booking.paymentDetails?.rentalTime} ${getRateTypeString()} (${booking.paymentDetails.proratedBillingDays} days pro-rated)` :
                                      `${booking.paymentDetails?.rentalTime} ${getRateTypeString()}`}
                                  </StyledTableCell>
                                  {!website.organisation.closeGroupSharingEnabled && <>
                                  <StyledTableCell>
                                    {toCurrency(rentalTaxAmount, currency, locale)}
                                  </StyledTableCell>
                          
                                  <StyledTableCell>
                                    {toCurrency(
                                      (Math.round(getApplicableRentalAmount(
                                        vehicleGroup.baseRate,
                                        vehicleGroup.priceRuleOperator,
                                        vehicleGroup.priceRuleValueType,
                                        vehicleGroup.priceRuleAmount
                                      ) *
                                        vehicleGroup.count *
                                        (booking.paymentDetails?.rentalTime || 1) * proratedFraction) +
                                        rentalTaxAmount),
                                      currency,
                                      locale
                                    )}
                                  </StyledTableCell>
                                  </>
                                  }
                                </StyledTableRow>
                              </>
                            );
                          }
                        )}
                        {(() => {
                          if (booking.insurancePolicy?.id) {
                            const totalVehicles = booking.vehicleGroups.reduce(
                              (accumulator, current) => accumulator + current.count,
                              0
                            );
                            return (
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography variant="body1">
                                    {"Insurance"}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {booking?.insurancePolicy?.name}
                                </StyledTableCell>
                                <StyledTableCell>{totalVehicles}</StyledTableCell>
                                <StyledTableCell>
                                  {toCurrency(
                                    booking?.insuranceRate,
                                    currency,
                                    locale
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {isProrated ?
                                    `${booking.paymentDetails?.rentalTime} ${getRateTypeString()} (${booking.paymentDetails.proratedBillingDays} days pro-rated)` :
                                    `${booking.paymentDetails?.rentalTime} ${getRateTypeString()}`}
                                </StyledTableCell>
                                <StyledTableCell>N/A</StyledTableCell>
                                <StyledTableCell>
                                  {toCurrency(
                                    booking?.insuranceRate *
                                    totalVehicles *
                                    (booking.paymentDetails?.rentalTime || 1) * proratedFraction,
                                    currency,
                                    locale
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          }
                        })()}
                        {booking?.addonRequirements?.map(
                          (addonR: IAddonRequirement, idx: number) => {
                            let taxAmount = 0;
                            let taxDescription = "";
                            if (addonR.hasFixedRate) {
                              taxAmount =
                                addonR.tax && addonR.tax.value
                                  ? ((addonR.rate * addonR.tax.value) / 100) *
                                  addonR.quantity
                                  : 0;
                            } else {
                              taxAmount =
                                addonR.tax && addonR.tax.value
                                  ? ((addonR.rate * addonR.tax.value) / 100) *
                                  addonR.quantity *
                                  booking.paymentDetails.rentalTime
                                  : 0;
                            }
                            if (addonR.tax && addonR.tax.value && addonR.tax.title) {
                              taxDescription = `(${addonR.tax.title} ${addonR.tax.value}%)`;
                            }
                            return (
                              <>
                                <StyledTableRow>
                                  {idx === 0 && (
                                    <StyledTableCell
                                      rowSpan={booking.addonRequirements.length}
                                    >
                                      <Typography variant="body1">
                                        {"Add-Ons"}
                                      </Typography>
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell colSpan={1}>
                                    {addonR.addon.displayName
                                      ? addonR.addon.displayName
                                      : addonR.addon.name.toUpperCase()}{" "}
                                    <br />
                                    {taxDescription}
                                  </StyledTableCell>
                                  <StyledTableCell>{addonR.quantity}</StyledTableCell>
                                  <StyledTableCell>
                                    {toCurrency(addonR.rate, currency, locale)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {addonR.hasFixedRate ?
                                      "N/A" :
                                      isProrated ?
                                        `${booking.paymentDetails?.rentalTime} ${getRateTypeString()} (${booking.paymentDetails.proratedBillingDays} days pro-rated)` :
                                        `${booking.paymentDetails?.rentalTime} ${getRateTypeString()}`
                                    }
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {toCurrency(
                                      addonR.hasFixedRate ? taxAmount : Math.round(taxAmount * proratedFraction),
                                      currency,
                                      locale
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {toCurrency(
                                      getAddonRate(addonR, booking.paymentDetails?.rentalTime) + (addonR.hasFixedRate ? taxAmount : Math.round(taxAmount * proratedFraction)),
                                      currency,
                                      locale
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            );
                          }
                        )}
                        {booking?.locationSurcharges?.map(
                          (surcharge: ILocationSurchargeRules, idx: number) => {
                            let taxDescription = "";
                            const vehicleCount = booking.vehicleGroups?.reduce(
                              (count: number, vehicleGroup) => {
                                return count + vehicleGroup.count;
                              },
                              0
                            );
                            if (
                              surcharge.tax &&
                              surcharge.tax.title &&
                              surcharge.tax.value
                            ) {
                              taxDescription = `(${surcharge.tax.title} ${surcharge.tax.value}%)`;
                            }
                            let surchargeTaxAmount = 0;
                            let surchargeAmount = 0;
                            if (surcharge.type === "PERCENTAGE") {
                              surchargeAmount = Math.round(
                                (booking.paymentDetails.rentalAmount *
                                  surcharge.value) /
                                100
                              );
                              surchargeTaxAmount = Math.round(
                                (surchargeAmount * surcharge.tax.value) / 100
                              );
                            } else {
                              if (surcharge.tax && surcharge.tax.value) {
                                if (surcharge.rate === "PER_DAY") {
                                  surchargeTaxAmount =
                                    ((surcharge.value * surcharge.tax.value) / 100) *
                                    bookingDurationInDays *
                                    vehicleCount;
                                } else if (surcharge.rate === "ONE_TIME") {
                                  surchargeTaxAmount =
                                    ((surcharge.value * surcharge.tax.value) / 100) *
                                    vehicleCount;
                                }
                              }
                              if (surcharge.rate === "PER_DAY") {
                                surchargeAmount =
                                  surcharge.value *
                                  bookingDurationInDays *
                                  vehicleCount;
                              } else if (surcharge.rate === "ONE_TIME") {
                                surchargeAmount = surcharge.value * vehicleCount;
                              }
                            }
                            return (
                              <>
                              {!website.organisation.closeGroupSharingEnabled && (
                                <StyledTableRow>
                                  {idx === 0 && (
                                    <StyledTableCell
                                      rowSpan={booking.locationSurcharges.length}
                                    >
                                      <Typography variant="body1">
                                        {"Location Surcharges"}
                                      </Typography>
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell colSpan={1}>
                                    {surcharge.name} <br />
                                    {surcharge.type === "PERCENTAGE" && (
                                      <span>
                                        {" "}
                                        (
                                        {`${surcharge.value}% of total rental amount`}
                                        ){" "}
                                      </span>
                                    )}{" "}
                                    <br />
                                    {taxDescription && (
                                      <span>{taxDescription}</span>
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>{surcharge.type !== "PERCENTAGE" ? vehicleCount : "NA"}</StyledTableCell>
                                  <StyledTableCell>
                                    {surcharge.type === "PERCENTAGE"
                                      ? toCurrency(surcharge.payableAmount || 0, currency, locale)
                                      : toCurrency(surcharge.value, currency, locale)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {surcharge.type === "PERCENTAGE" ? "N/A" : surcharge.rate === "PER_DAY" ? `${booking.paymentDetails?.rentalTime} ${getRateTypeString()}` : "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {surcharge.tax && surcharge.tax.value ?
                                      toCurrency(surcharge.payableTaxAmount || 0, currency, locale) :
                                      toCurrency(0, currency, locale)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {surcharge.tax && surcharge.tax.value ?
                                      toCurrency(
                                        ((surcharge.payableAmount || 0) + (surcharge.payableTaxAmount || 0)),
                                        currency,
                                        locale
                                      ) : toCurrency(
                                        surcharge.payableAmount || 0,
                                        currency,
                                        locale
                                      )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                                
                              </>
                            );
                          }
                        )}
                        {(() => {
                          if (!website.organisation.closeGroupSharingEnabled && booking.oneWayRentalFee && booking.oneWayRentalFee.amount) {
                            const totalVehicles = booking.vehicleGroups.reduce(
                              (accumulator, current) => accumulator + current.count,
                              0
                            );
                            const oneWayRentalTax = booking.oneWayRentalFee.taxable ? Math.round((booking.oneWayRentalFee.amount * totalVehicles * booking.oneWayRentalFee.tax.value) / 100):0;
                            return (
                              <StyledTableRow>
                                <StyledTableCell>
                                  <Typography variant="body1">
                                    {"One Way Fee"}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {'One Way Rental Charge'}
                                </StyledTableCell>
                                <StyledTableCell>{totalVehicles}</StyledTableCell>
                                <StyledTableCell>
                                  {toCurrency(
                                    booking?.oneWayRentalFee.amount,
                                    currency,
                                    locale
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {"NA"}
                                </StyledTableCell>
                                <StyledTableCell>{toCurrency(oneWayRentalTax, currency, locale)}</StyledTableCell>
                                <StyledTableCell>
                                  {toCurrency(
                                    (booking?.oneWayRentalFee.amount * totalVehicles) + oneWayRentalTax,
                                    currency,
                                    locale
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          }
                        })()}
                        <StyledTableRow>
                          <StyledTableCell
                            colSpan={5}
                            rowSpan={booking.discount.amount > 0 ? 4 : 3}
                          >
                            <>
                              {(booking.rateTypeName === "weekly" ||
                                booking.rateTypeName === "monthly") && booking.billingFrequency && (
                                  <Table stickyHeader className="no-row-hover">
                                    <TableHead style={{paddingTop: "10px"}}>
                                      <StyledTableRow>
                                      {!website.organisation.closeGroupSharingEnabled &&
                                        <Typography variant="h4">
                                            Next Billing ({booking.billingFrequency} {rateTypeName(booking.billingFrequency, booking.rateTypeName)})
                                          </Typography>
                                      }
                                      </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                    {!website.organisation.closeGroupSharingEnabled && <>
                                      <StyledTableRow>
                                        <StyledTableCell>
                                        
                                          <Typography variant="body1">
                                            Sub Total
                                          </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            borderLeft:
                                              "solid 1px rgba(224, 224, 224, 1)",
                                          }}
                                        >
                                          {toCurrency(
                                            (weeklyRentalAmount +
                                              weeklyAddonAmount +
                                              weeklyInsuranceAmount),
                                            currency,
                                            locale
                                          )}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      </>
                                    }
                                      {booking.discount.amount > 0 && (
                                        <StyledTableRow>
                                          <StyledTableCell>
                                            <Typography variant="body1">
                                              Discount
                                            </Typography>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            style={{
                                              borderLeft:
                                                "solid 1px rgba(224, 224, 224, 1)",
                                            }}
                                          >
                                            -{" "}
                                            {toCurrency(
                                              booking.discount.amount /
                                              (booking.paymentDetails?.rentalTime || 1),
                                              currency,
                                              locale
                                            )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      )}
                                      {!website.organisation.closeGroupSharingEnabled && <>
                                      <StyledTableRow>
                                        <StyledTableCell>
                                          <Typography variant="body1">
                                            VAT/TAX
                                          </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            borderLeft:
                                              "solid 1px rgba(224, 224, 224, 1)",
                                          }}
                                        >
                                          {toCurrency(
                                            weeklyTaxAmount,
                                            currency,
                                            locale
                                          )}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      <StyledTableRow>
                                        <StyledTableCell>
                                          <Typography variant="body1">
                                            Total
                                          </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            borderLeft:
                                              "solid 1px rgba(224, 224, 224, 1)",
                                          }}
                                        >
                                          {toCurrency(
                                            (weeklyRentalAmount +
                                              weeklyAddonAmount +
                                              weeklyInsuranceAmount +
                                              weeklyTaxAmount),
                                            currency,
                                            locale
                                          )}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      </>
                                      }
                                    </TableBody>
                                  </Table>
                                )}
                              {
                                booking.taxExempted ?
                                  <Typography variant="h4">
                                    {`*This is a ${getLocalizedTaxSyntex(country)} exempted booking.`}
                                  </Typography> : <Typography></Typography>
                              }
                              {booking.zeroCostBooking ?
                                <Typography variant="h4">
                                  *This is a test/courtesy {getLocalizedBookingSyntex(country).toLowerCase()}.
                                </Typography> : <Typography></Typography>
                              }
                            </>
                          </StyledTableCell>
                          {!website.organisation.closeGroupSharingEnabled && <>
                          <StyledTableCell
                            style={{ borderLeft: "solid 1px rgba(224, 224, 224, 1)" }}
                          >
                            <Typography variant="h4">
                              {`${booking.rateTypeName === RATE_TYPES.WEEKLY && booking.billingFrequency == 1
                                ? "First Week Total"
                                : booking.rateTypeName === RATE_TYPES.MONTHLY && booking.billingFrequency == 1
                                  ? "First Month Total"
                                  : "Grand Total"
                                }`}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            {toCurrency(
                              booking?.paymentDetails?.totalPayableAmount,
                              currency,
                              locale
                            )}
                          </StyledTableCell>
                          </>
                          }
                        </StyledTableRow>
                        {booking.discount.amount > 0 && (
                          <StyledTableRow>
                            <StyledTableCell
                              style={{
                                borderLeft: "solid 1px rgba(224, 224, 224, 1)",
                              }}
                            >
                              <Typography variant="h4">{"DISCOUNT"}</Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                              -{" "}
                              {toCurrency(booking.paymentDetails.discountAmount, currency, locale)}
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                          </StyledTableRow>
                        )}
                      </>
                    )

                  }

                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.box}>
        {!website.organisation.closeGroupSharingEnabled && 
          <Typography variant="body1">
            {`*${getLocalizedTaxSyntex(country)} is calculated against Rental Price and some selected
            Add-Ons "(Excluding Insurance and Deposit)"`}
          </Typography>
        }
          {(booking.rateTypeName === "weekly" ||
            booking.rateTypeName === "monthly") && !website.organisation.closeGroupSharingEnabled && (
              <Typography
                variant="body1"
                className="semi-bold"
                style={{ paddingTop: 5 }}
              >
                *A recurring weekly/monthly invoice will be generated
                automatically for the next week/month.
              </Typography>
            )}
          {booking.zeroCostBookingNote && (
            <>
              <Typography variant="h4" style={{ marginTop: 5 }}>
                Additional Note:
              </Typography>
              <Typography variant="body1">
                {booking.zeroCostBookingNote}
              </Typography>
            </>
          )}
          {!website.organisation.closeGroupSharingEnabled && 
          <Typography
            variant="h3"
            // className="semi-bold"
            style={{ paddingTop: 5, fontWeight: "bolder" }}
          >
            {collectDeposit ? "Deposit: " : "Deposit (Payable at the time of vehicle collection): "}
            <span className="margin-left">
              {toCurrency(
                booking.depositDetails?.amount || 0,
                currency,
                locale
              )}
            </span>
          </Typography>
          }
        </div>
      </Grid>
    </Grid>
  );
};

export default Quote;