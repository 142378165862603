import { MutationHookOptions, QueryHookOptions } from "@apollo/client";

import { DefaultAddons } from "./views/NewBooking/SelectAddOns/types";
import BABY_SEAT from "./../assets/icons/baby-seat-addon.svg";
import INSURANCE from "./../assets/icons/car-insurance.svg";
import CONVERTIBLE_ICON from "./../assets/icons/convertable.svg";
import CUSTOM_ADDON from "./../assets/icons/custom-addon.svg";
import DOOR_ICON from "./../assets/icons/door.svg";
import SUV_ICON from "./../assets/icons/fourByFour.svg";
import PUMP_ICON from "./../assets/icons/gasoline-pump.svg";
import GEAR_ICON from "./../assets/icons/gearshift.svg";
import GENERAL_ADDON from "./../assets/icons/general-add-ons.svg";
import GPS from "./../assets/icons/gps-addon.svg";
import HATCHBACK_ICON from "./../assets/icons/hatchback.svg";
import MPV_ICON from "./../assets/icons/mpv.svg";
import SEAT_ICON from "./../assets/icons/safety-seat.svg";
import SALOON_ICON from "./../assets/icons/saloon.svg";
import SPORTS_ICON from "./../assets/icons/sports.svg";
import SCOOTER_ICON from "./../assets/icons/scooter.svg";
import ADDITIONAL_DRIVER from "./../assets/icons/taxi.svg";
import TOURER_ICON from "./../assets/icons/tourer.svg";
import VALET from "./../assets/icons/valet-addon.svg";
import { ITaxInput, ITax, IVehicleGroup, IBookingCreateInput, BILLING_CYCLE_NAME, IBooking } from "./../reducers/bookings/types";
import { IFilter, IFilterOption } from "./FilterSection/FilterSection";
import { CURRENCY_SYMBOLS } from "./consts";
import { Storage } from "aws-amplify";
import { getSignedUrl } from "../utils/getSignedUrl";
import { RATE_TYPES } from "./views/utils";

const CONVERTIBLE = "convertible";
const COUPE = "coupe";
const ESTATE = "estate";
const HATCHBACK_5_DOOR = "5 door hatchback";
const HATCHBACK = "hatchback";
const MPV = "mpv";
const SALOON = "saloon";
const SUV = "suv";
const SCOOTER = "scooter";
const UNDEFINED = "undefined";

const vehicleCategories: { [index: string]: string } = {
  [CONVERTIBLE]: CONVERTIBLE_ICON,
  [COUPE]: SPORTS_ICON,
  [ESTATE]: TOURER_ICON,
  [HATCHBACK]: HATCHBACK_ICON,
  [HATCHBACK_5_DOOR]: HATCHBACK_ICON,
  [MPV]: MPV_ICON,
  [SALOON]: SALOON_ICON,
  [SUV]: SUV_ICON,
  [SCOOTER]: SCOOTER_ICON,
  [UNDEFINED]: MPV_ICON,
};

const featureCategories: { [index: string]: string } = {
  fuelType: PUMP_ICON,
  numberOfDoors: DOOR_ICON,
  numberOfSeats: SEAT_ICON,
  transmission: GEAR_ICON,
};

const BillingCycle: { [key: string]: number } = {
  MONTHLY: 30,
  WEEKLY: 7,
};

export function getVehicleTypeIcon(category: string) {
  const myCategory = category.toLowerCase();
  return vehicleCategories[myCategory]
    ? vehicleCategories[myCategory]
    : vehicleCategories[UNDEFINED];
}

export function getFeatureTypeIcon(feature: string) {
  return featureCategories[feature];
}

export function getAddonIcon(category: string) {
  switch (category) {
    case "insurance":
      return INSURANCE;
    case DefaultAddons.VALET:
      return VALET;
    case DefaultAddons.BABY_SEAT:
      return BABY_SEAT;
    case DefaultAddons.GPS:
      return GPS;
    case DefaultAddons.ADDITIONAL_DRIVER:
      return ADDITIONAL_DRIVER;
    case DefaultAddons.CUSTOM_ADDON:
      return GENERAL_ADDON;
    default:
      return CUSTOM_ADDON;
  }
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export type OptionsHeaders = MutationHookOptions | QueryHookOptions | undefined;

export function toCurrency(value: number, currency: string, locale: string) {
  const currencySymbol = CURRENCY_SYMBOLS[currency]
    ? CURRENCY_SYMBOLS[currency]
    : "";
  return `${currencySymbol}${numberWithCommas((value / 100).toFixed((currency === 'JPY') ? 0 : 2))}`;
}

function numberWithCommas(x: string): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatLicenceNumber(licence: string) {
  if (licence.length === 7) {
    return licence.toUpperCase().replace(/^(.{4})(.*)$/, "$1 $2");
  }
  return licence.toUpperCase();
}

export function generateFiltersFromVehicles(vehicles: IVehicleGroup[]) {
  const filters: { [key: string]: IFilter } = {
    bodyType: { name: "Car Type", id: "bodyType", options: [] },
    colour: { name: "Colour", id: "colour", options: [] },
    fuelType: { name: "Fuel Type", id: "fuelType", options: [] },
    make: { name: "Make", id: "make", options: [] },
    model: { name: "Model", id: "model", options: [] },
    transmission: { name: "Transmission", id: "transmission", options: [] },
    year: { name: "Year", id: "year", options: [] },
  };
  vehicles.forEach((vehicle: IVehicleGroup) => {
    Object.keys(vehicle).forEach((key: string) => {
      populateFilters(filters, vehicle, key);
    });
  });

  return Object.values(filters);
}

function populateFilters(filters: any, object: any, key: string) {
  if (filters[key]) {
    const exists =
      filters[key].options.filter(
        (option: IFilterOption) =>
          option.id ===
          (typeof object[key] === "string"
            ? object[key].toLowerCase()
            : object[key].toString().toLowerCase())
      ).length === 0
        ? false
        : true;

    if (!exists) {
      filters[key].options.push({
        id:
          typeof object[key] === "string"
            ? object[key].toLowerCase()
            : object[key].toString().toLowerCase(),
        name: object[key],
      });
    }
  }
}

export function formatGraphQLErrorMessage(message: string) {
  const title = "GraphQL error: ";
  return message.replace(title, "");
}

export function getFileExtension(mimeType: string) {
  switch (mimeType) {
    case "application/pdf":
      return "pdf";
    case "image/jpeg":
      return "jpg";
    case "image/png":
      return "png";
    default:
      return "pdf";
  }
}

export const returnfileContentTypeAndExtention = (file: File) => {
  let fileExtension: string = "";
  let contentType: string = "";
  if (file.type === "application/pdf") {
    fileExtension = "pdf";
    contentType = "application/pdf";
  } else if (file.type === "image/jpeg") {
    fileExtension = "jpeg";
    contentType = "image/jpeg";
  } else if (file.type === "image/jpg") {
    fileExtension = "jpg";
    contentType = "image/jpg";
  } else if (file.type === "image/png") {
    fileExtension = "png";
    contentType = "image/png";
  } else if (file.type === "image/bmp") {
    fileExtension = "bmp";
    contentType = "image/bmp";
  }
  return { fileExtension, contentType };
};

export const getUploadedImageByKey = async (key: string) => {
  try {
    let contentType;
    const fileExtension = key.split(".")[1];
    if (
      fileExtension === "jpeg" ||
      fileExtension === "png" ||
      fileExtension === "jpg"
    ) {
      contentType = "imgage/*";
    } else {
      contentType = "application/pdf";
    }
    const file = await getSignedUrl(key);
    return file;
  } catch (error) {
    console.log(error);
  }
};

export const isValidUrl = async (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

export function getCycleByValue(value: number) {
  return Object.keys(BillingCycle).filter(
    (key: string) => BillingCycle[key] === value
  )[0];
}

export function capitalize(value: string) {
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
}

export function rateTypeName(value: number, type: string) {
  let title;
  if (type === RATE_TYPES.MONTHLY) {
    title = "Month";
  } else if (type === RATE_TYPES.WEEKLY) {
    title = "Week" 
  }
  return value > 1 ? title + "s" : title;
}

export const checkUploadFileFormat = (fileType: string) => {
  if (
    fileType === "application/pdf" ||
    fileType === "application/msword" ||
    fileType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return true;
  }
};

export const uploadFileExtensionAndContentType = (fileType: string) => {
  let fileExtension: string = "";
  let contentType: string = "";
  if (fileType === "application/pdf") {
    fileExtension = "pdf";
    contentType = "application/pdf";
  } else if (fileType === "application/msword") {
    fileExtension = "doc";
    contentType = "applicaiton/msword";
  } else if (
    fileType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    fileExtension = "docx";
    contentType =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  }
  return { fileExtension, contentType };
};

export const checkDeleteOrDownloadFileType = (documentUrl: string) => {
  const fileExtension = documentUrl.split(".")[1];
  let contentType: string = "";
  if (fileExtension === "doc") {
    contentType = "application/msword";
  } else if (fileExtension === "docx") {
    contentType =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  } else if (fileExtension === "pdf") {
    contentType = "application/pdf";
  }
  return { fileExtension, contentType };
};

export const returnSortedOrder = (tableData: any) => {
  if (tableData && tableData.direction === "descending") {
    return "desc";
  } else if (tableData && tableData.direction === "ascending") {
    return "asc";
  }
};

export const setTableSortOrder = (
  tableColumns: any,
  tableData: any,
  sortOrder: any
) => {
  for (let index = 0; index < tableColumns.length; index++) {
    const element: any = tableColumns[index];
    if (element.hasOwnProperty("options")) {
      element.options.sortDirection = "none";
      if (tableData && tableData.columnName === element.name) {
        element.options.sortDirection = sortOrder;
      }
    } else {
      element.options = { sortDirection: "none" };
      if (tableData && tableData.columnName === element.name) {
        element.options = { sortDirection: sortOrder };
      }
    }
  }
};

export const possibleCsvMimeTypes = [
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/csv",
  "application/x-csv",
  "text/csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
  "",
];

export const INVOICE = "INVOICE";
export const BOOKING = "BOOKING";

export const getRetnalTaxAmount = (
  tax: ITaxInput[],
  taxableAmount: number,
  rentalTime: number,
  vehicleCount: number
) => {
  let payableTaxAmount = 0;
  tax.forEach((taxObj) => {
    if (taxObj.type === "PERCENTAGE" && taxObj.rate === "ONE_TIME") {
      payableTaxAmount += Math.round((taxObj.amount * taxableAmount) / 100);
      if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * vehicleCount;
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * rentalTime * vehicleCount;
      }
    } else if (taxObj.type === "PERCENTAGE" && taxObj.rate === "PER_DAY") {
      payableTaxAmount += Math.round((taxObj.amount * taxableAmount) / 100);
      if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * vehicleCount;
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * rentalTime * vehicleCount;
      }
    } else if (taxObj.type === "FIXED" && taxObj.rate === "ONE_TIME") {
      payableTaxAmount += taxObj.amount * vehicleCount;
      if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * vehicleCount;
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * rentalTime * vehicleCount;
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      }
    } else if (taxObj.type === "FIXED" && taxObj.rate === "PER_DAY") {
      payableTaxAmount += taxObj.amount * rentalTime * vehicleCount;
      if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * rentalTime * vehicleCount;
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "FIXED" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += taxObj.subdivisionValue * vehicleCount;
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "ONE_TIME"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      } else if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionType === "PERCENTAGE" &&
        taxObj.subdivisionRate === "PER_DAY"
      ) {
        payableTaxAmount += Math.round(
          (taxObj.subdivisionValue * taxableAmount) / 100
        );
      }
    } else if (!taxObj.type) {
      payableTaxAmount += Math.round((taxObj.amount * taxableAmount) / 10000);
    }
  });
  return payableTaxAmount;
};

export const getTaxDescription = (
  taxes: ITaxInput[],
  currency: string,
  locale: string
) => {
  const taxTitles: string[] = [];
  let title;
  if (taxes) {
    taxes.forEach((taxObj) => {
      let subdivisionTaxValue =
        taxObj.subdivisionType === "PERCENTAGE"
          ? taxObj.subdivisionValue
          : toCurrency(taxObj.subdivisionValue, currency, locale);
      if (
        taxObj.subdivisionValue &&
        taxObj.subdivisionRate &&
        taxObj.subdivisionType
      ) {
        title = `${taxObj.code} ${
          taxObj.type === "PERCENTAGE"
            ? taxObj.amount
            : toCurrency(taxObj.amount, currency, locale)
        }${
          taxObj.type === "PERCENTAGE"
            ? `% ${taxObj.rate === "PER_DAY" ? "/day" : ""}`
            : taxObj.rate === "PER_DAY"
            ? "/day"
            : ""
        } + ${subdivisionTaxValue}${
          taxObj.subdivisionType === "PERCENTAGE"
            ? `% ${taxObj.subdivisionRate === "PER_DAY" ? "/day" : ""}`
            : taxObj.subdivisionRate === "PER_DAY"
            ? "/day"
            : ""
        }`;
      } else if (!taxObj.type) {
        title = `${taxObj.code} ${taxObj.amount / 100}%`;
      } else if (taxObj.type === "PERCENTAGE" && taxObj.rate === "PER_DAY") {
        title = `${taxObj.code} ${taxObj.amount}%/day`;
      } else {
        title = `${taxObj.code} ${
          taxObj.type === "PERCENTAGE"
            ? taxObj.amount
            : toCurrency(taxObj.amount, currency, locale)
        }${
          taxObj.type === "PERCENTAGE"
            ? `% ${taxObj.rate === "PER_DAY" ? "/day" : ""}`
            : taxObj.rate === "PER_DAY"
            ? "/day"
            : ""
        }`;
      }
      taxTitles.push(title);
    });
  }
  return taxTitles;
};

export enum DateTimeFormat {
  US_DATE_EXPANDED = "MM/dd/yyyy, hh:mm a",
  US_DATE_CONDENSED = "MM/dd/yyyy",
  DEFAULT_DATE_EXPANDED = "dd/MM/yyyy, hh:mm a",
  DEFAULT_DATE_CONDENSED = "dd/MM/yyyy"
}

export const DATE_TYPE = {
  EXPANDED: "EXPANDED",
  CONDENSED: "CONDENSD"
};

export const mapFuelTypesAndDisplayType = (fuelType?: string) => {
  let fuelOnly = false, batteryOnly = false, fuelAndBattery = false;
  fuelType = fuelType?.toUpperCase();
  switch (fuelType) {
    case "DIESEL":
    case "PETROL":
    case "GASOLINE":
    case "GAS BI-FUEL":
      fuelOnly = true;
      break;
    case "ELECTRICITY":
    case "ELECTRIC":
      batteryOnly = true;
      break;
    case "HYBRID ELECTRIC":
    case "ELECTRIC DIESEL":
    case "HYBRID ELECTRIC (CLEAN)":
    default:
      fuelAndBattery = true;
      break;
  }
  return {
    fuelOnly,
    batteryOnly,
    fuelAndBattery
  }
}

export const isLongTermBooking = (bookingData: IBookingCreateInput | IBooking) => {
  const longTermBooking =
    bookingData.hasOwnProperty("isRecurringBilling") &&
      typeof bookingData.isRecurringBilling === "boolean"
      ? bookingData.isRecurringBilling
      : [BILLING_CYCLE_NAME.WEEKLY, BILLING_CYCLE_NAME.MONTHLY].includes(
        bookingData.rateTypeName
      );
  return longTermBooking;
};
