import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useParams } from "react-router-dom";
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import {
  BookingStatus,
  IInvoice,
  IInvoiceItem,
  IInvoiceItemType,
} from "../../../../reducers/bookings/types";
import { StyledTableCell, StyledTableRow } from "../../../StyledTable";
import { DATE_TYPE, formatGraphQLErrorMessage, getTaxDescription, toCurrency } from "../../../utils";
import { DateTime as d, Settings } from "luxon";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import { GET_INVOICE } from "../../../../graphql/invoices/getInvoiceQuery";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import DownloadPdf from "../../../Pdf/DownloadPdf";
import { InvoiceStatus, InvoiceType } from "../../NewBooking/Invoices/utils";
import InvoicePaymentDialog from './PaymentDialog';
import { getLocalizedBookingSyntex, getLocalizedDateFormat, getLocalizedTaxSyntex } from "../../../../utils/localized.syntex";
import { CustomerType } from "../../../../reducers/auth/types";
import { ADD_BOOKING_PO_NUMBER } from "../../../../graphql/bookings/addBookingPoNumberMutation";

export interface IDamageList {
  number: number;
  damageKind: string;
  image: string;
}

const Invoice = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const { id: invoiceId } = useParams<{ id: string }>();
  const [invoice, setInvoice] = useState<IInvoice>();
  const [poNumber, setPoNumber] = useState<string>("");
  const [isEditablePONumber, setIsEditablePONumber] = useState<boolean>(false);
  const website = useSelector(
    (state: IAppState) => state.consumerWebsiteReducer.consumerWebsite
  );
  const { currency, locale } = website.organisation;
  const { country } = website.organisation.address;
  const [ hideDetails, setHideDetails ] = useState<boolean>(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);

  const [getInvoice, { loading: bookingLoading, data: invoiceData }] =
    useLazyQuery(GET_INVOICE, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data.consumerInvoice) {
          history.push("/account/invoices");
        }
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    });

  const [addBookingPoNumber] = useMutation(ADD_BOOKING_PO_NUMBER, {
    onCompleted: (data) => {
      snackbar({
        message: `${getLocalizedBookingSyntex(country)} purchase order number updated successfully`,
        variant: SnackBarVariant.SUCCESS
      })
      if (invoice && invoice.booking?.id) {
        setInvoice({
          ...invoice,
          booking: {
            ...invoice?.booking,
            poNumber: data?.addBookingPoNumber || ""
          }
        })
      }
      setPoNumber(data.addBookingPoNumber)
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  useEffect(() => {
    if (invoiceId) {
      getInvoice({
        variables: {
          id: invoiceId,
        },
      });
    }
  }, [invoiceId]);

  useEffect(() => {
    if (invoiceData && invoiceData.consumerInvoice) {
      const _invoice: IInvoice = invoiceData.consumerInvoice;
      setInvoice(_invoice);
      setPoNumber(_invoice.booking?.poNumber || "")
      setHideDetails([BookingStatus.REQUIRES_APPROVAL, BookingStatus.CONFIRMED].includes(_invoice.booking.status));
      Settings.defaultZone = invoice?.booking?.branch?.timeZone || "Europe/London"
      if (invoiceData.consumerInvoice?.adjustedDeposit?.amount && invoiceData.consumerInvoice.booking.depositDetails?.payment) {
        const payments: any[] = [...invoiceData.consumerInvoice.payments];
        if (!payments.some((payment) => payment.id === invoiceData.consumerInvoice.booking.depositDetails?.payment.id)) {
          payments.push({
            ...invoiceData.consumerInvoice.booking.depositDetails?.payment,
            amount: invoiceData.consumerInvoice.adjustedDeposit.amount,
            dateCreated: invoiceData.consumerInvoice.adjustedDeposit.adjustedAt,
            paymentMode: "Paid by Deposit",
            refundAmount: 0,
          })
          setInvoice({
            ...invoiceData.consumerInvoice,
            payments
          })
        }
      }
    }
    return () => {
      Settings.defaultZone = "Europe/London"
    }
  }, [invoiceData]);

  if (!invoice || !invoiceData) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress size={30} thickness={5} />
      </div>
    );
  }

  const registrationNumbers: string[] = [];
  invoice.booking?.currentBookingSchedules.forEach((schedule) => {
    if (schedule.vehicle) {
      registrationNumbers.push(schedule.vehicle.isGhostVehicle ? "To be assigned" : schedule.vehicle.licencePlate);
    }
  });

  const columns = [
    "#",
    "Particulars",
    "Quantity",
    "Unit Price",
    "Duration",
    "VAT/TAX",
    "Amount",
  ];
  const paymentColumns = ["Date", "Amount", "Mode Of Payments", "Status"];

  const consolidatedInvoicesColumns = ["#", "Invoice Reference Number"];

  const getRateTypeString = (rateType: string) => {
    if (rateType) {
      switch (rateType) {
        case "hourly":
          return "Hour (s)";
        case "daily":
          return "Day (s)";
        case "weekly":
          return "Week (s)";
        case "monthly":
          return "Month (s)";
        default:
          return "Day (s)";
      }
    }
  };

  const addPoNumberToBooking = (_poNumber: string) => {
    if (invoice.booking && invoice.booking.id) {
      addBookingPoNumber({
        variables: {
          bookingId: invoice.booking.id,
          poNumber: _poNumber
        }
      })
    }
  }

  return (
    <div className={styles.column}>
      <div className="flex cross-center">
        {paymentDialogOpen && (
          <InvoicePaymentDialog
            open={paymentDialogOpen}
            setOpen={(open: boolean) => setPaymentDialogOpen(open)}
            invoice={invoice}
            bookingId={invoice.booking?.id}
            onConfirmation={(invoice) => {

            }}
            returnUrl={`account/invoices/${invoice.id}`}
          />
        )}
        <Typography
          variant="h4"
          className="semi-bold flex cross-center space-between"
        >
          <IconButton
            size="small"
            edge="start"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <span className="margin-left">Invoice Summary</span>
        </Typography>
        <div className="flex fill main-end">
          <DownloadPdf
            getSource={{ type: "INVOICE", id: invoice.id }}
            pdfSource={invoice.invoiceUrl}
            fileName={`${invoice.invoiceType === InvoiceType.SUNDRY ? "Sundry" : ""
              }Invoice-${invoice?.invoiceRef}.pdf`}
          />
          {invoice.dueAmount ? <>
            <span className="padding-right"></span>
            <Button
              onClick={() =>
                setPaymentDialogOpen(true)
              }
              variant="contained"
              color="primary"
              size="large"
              disabled={invoice.booking?.status === BookingStatus.REQUIRES_APPROVAL}
              style={{
                background: "var(--primary-color)",
                textTransform: "initial",
                boxShadow: "none",
                fontSize: 14,
                fontWeight: 500,
                color: invoice.booking?.status === BookingStatus.REQUIRES_APPROVAL ? "rgba(255,255,255,0.3)"
                  : "primary",
              }}
            >
              Add Payment
            </Button></> : null}
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} md={4} style={{ display: "flex" }}>
          <div className={styles.card}>
            {invoice.businessCustomer && <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Business Name:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {invoice.businessCustomer.businessName}
              </Typography>
            </div>}
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Customer Name:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {invoice.businessCustomer ? invoice.businessCustomer.contact.firstName : invoice.customer.firstName}{" "}
                {invoice.businessCustomer ? invoice.businessCustomer.contact.lastName : invoice.customer.lastName}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Customer Email:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {invoice.businessCustomer ? invoice.businessCustomer.billing.email : invoice.customer.email}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Customer Phone Number:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {invoice.businessCustomer ? invoice.businessCustomer.billing.phoneNumber.phone : invoice.customer.phoneNumber.phone}
              </Typography>
            </div>
            {invoice.businessCustomer && <div>
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Address:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {invoice.businessCustomer ? invoice.businessCustomer.registeredAddress.fullAddress : invoice.customer.address}
              </Typography>
            </div>}
          </div>
        </Grid>
        <Grid item xs={12} md={3} style={{ display: "flex" }}>
          <div className={styles.card}>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Invoice Reference:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {invoice.invoiceRef}
              </Typography>
            </div>
            {invoice.invoiceType !== InvoiceType.CONSOLIDATED && (
              <>
                <div className="margin-bottom">
                  <Typography
                    variant="body1"
                    className="opacity60 padding-bottom--small"
                  >
                    {`${getLocalizedBookingSyntex(country)} Reference:`}
                  </Typography>
                  <Typography variant="body1" className="semi-bold">
                    {invoice.booking.referenceNumber}
                  </Typography>
                </div>
                <div className="margin-bottom">
                  <Typography
                    variant="body1"
                    className="opacity60 padding-bottom--small"
                  >
                    {`${getLocalizedBookingSyntex(country)} Period:`}
                  </Typography>
                  <Typography variant="body1" className="semi-bold">
                    {
                      getLocalizedDateFormat(country, invoice.booking.pickupDateTime, DATE_TYPE.EXPANDED,invoice.booking.branch?.timeZone)
                    }
                    {" "}
                    -{" "}
                    {invoice.booking.dropoffDateTime &&
                      getLocalizedDateFormat(country, invoice.booking.dropoffDateTime, DATE_TYPE.EXPANDED,invoice.booking.branch?.timeZone)
                    }
                  </Typography>
                </div>
                {!hideDetails && 
                  <div className="margin-bottom">
                    <Typography
                      variant="body1"
                      className="opacity60 padding-bottom--small"
                    >
                      Vehicles:
                    </Typography>
                    <Typography variant="body1" className="semi-bold">
                      {registrationNumbers.length > 0
                        ? registrationNumbers.join(", ").toUpperCase()
                        : ""}
                    </Typography>
                  </div>
                }
                {invoice.booking.customerType === CustomerType.BUSINESS && <div>
                  {isEditablePONumber ? (<Grid item xs={10} container className={"po-number-main"}>
                    <TextField
                      label="PO Number"
                      id="po-number"
                      fullWidth
                      value={poNumber}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPoNumber(e.target.value)
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            {
                              <Tooltip title="Save" style={{ padding: 0 }}>
                                <IconButton
                                  aria-label="toggle po number "
                                  onClick={() => {
                                    addPoNumberToBooking(poNumber)
                                    setIsEditablePONumber(false)
                                  }}
                                >
                                  <DoneIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            <Tooltip title="Close" style={{ padding: 0 }}>
                              <IconButton
                                aria-label="toggle po number"
                                onClick={() => {
                                  setPoNumber(invoice?.booking?.poNumber || "")
                                  setIsEditablePONumber(false)
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )
                      }}
                    />
                  </Grid>) : (<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                      variant="body1"
                      className="opacity60 padding-bottom--small"
                    >
                      PO Number:
                    </Typography>
                    <Typography variant="body1" className="semi-bold">
                      {poNumber || "N/A"}
                    </Typography>
                    {invoice.status === InvoiceStatus.UNPAID && (<Grid >
                      <Tooltip title={poNumber ? "Edit" : "Click to add"} style={{ padding: 0 }}>
                        <IconButton
                          aria-label="toggle edit visibility"
                          onClick={() => setIsEditablePONumber(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>)}
                  </div>)}
                </div>}
              </>
            )}
            {invoice.consolidatedBy && (
              <div className="margin-bottom">
                <Typography
                  variant="body1"
                  className="opacity60 padding-bottom--small"
                >
                  {`Invoice Voided By:`}
                </Typography>
                <Typography 
                  variant="body1" 
                  className={`${styles.consolidatedInvoiceRow} semi-bold`}
                  onClick={() => history.push(`/account/invoices/${invoice.consolidatedBy?.id}`)}
                >
                  {invoice.consolidatedBy.invoiceRef}
                </Typography>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={5} style={{ display: "flex" }}>
          <div className={styles.card} style={{ marginRight: 0 }}>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Invoice Period:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {getLocalizedDateFormat(country, invoice.startDate, DATE_TYPE.EXPANDED,invoice.booking?.branch?.timeZone)}{" "}
                - {getLocalizedDateFormat(country, invoice.endDate, DATE_TYPE.EXPANDED,invoice.booking?.branch?.timeZone)}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Invoice Status:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                <span
                  style={{
                    padding: "0 5px",
                    borderRadius: 3,
                    color: ["PARTIALLY PAID", "UNPAID"].includes(invoice.status)
                      ? "#be0000"
                      : "#005b41",
                    backgroundColor: ["PARTIALLY PAID", "UNPAID"].includes(
                      invoice.status
                    )
                      ? "#e5c7c7"
                      : "#c7e5dc",
                  }}
                >
                  {["PARTIALLY PAID", "UNPAID"].includes(invoice.status)
                    ? "UNPAID"
                    : invoice.status}
                </span>
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Invoice Amount:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                Total:{" "}
                {toCurrency(invoice.totalPayableAmount, currency, locale)}
              </Typography>
              <Typography variant="body1" className="semi-bold">
                Amount Due: {toCurrency(invoice.dueAmount, currency, locale)}
              </Typography>
            </div>
            <div className="margin-bottom">
              <Typography
                variant="body1"
                className="opacity60 padding-bottom--small"
              >
                Generated At:
              </Typography>
              <Typography variant="body1" className="semi-bold">
                {getLocalizedDateFormat(country, invoice.dateCreated, DATE_TYPE.EXPANDED,invoice.booking?.branch?.timeZone)}
              </Typography>
            </div>
            {invoice.invoiceType !== InvoiceType.CONSOLIDATED && 
              <div>
                <Typography
                  variant="body1"
                  className="opacity60 padding-bottom--small"
                >
                  Due Date:
                </Typography>
                <Typography variant="body1" className="semi-bold">
                  {getLocalizedDateFormat(country, invoice.dueDate, DATE_TYPE.EXPANDED,invoice.booking?.branch?.timeZone)}
                </Typography>
              </div>
            }
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className={`${styles.box}`}>
            <div style={{ margin: "0 -10px -10px -10px" }}>
              <TableContainer style={{ maxHeight: "100%" }}>
                <Table stickyHeader aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      {columns.map((column: string) => {
                        return <StyledTableCell>{column === "VAT/TAX" ? getLocalizedTaxSyntex(country) : column}</StyledTableCell>;
                      })}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.invoiceItems?.map(
                      (item: IInvoiceItem, idx: number) => {
                        const rentalTaxDetails = getTaxDescription(invoice.tax, currency, locale);
                        let taxAmount = 0;
                        if (item.taxAmount) {
                          taxAmount = item.taxAmount;
                        }
                        let rateTypeName = item.rateTypeName ? item.rateTypeName : invoice.booking?.rateTypeName;
                        let multiplier = 1;
                        if (item.quantity) {
                          multiplier = multiplier * item.quantity;
                        } 
                        if (item.duration) {
                          multiplier = multiplier * item.duration;
                        }
                        return (
                          <StyledTableRow>
                            <StyledTableCell>{idx + 1}</StyledTableCell>
                            <StyledTableCell>
                              {item.type === IInvoiceItemType.RENTAL_AMOUNT
                                ? (invoice.booking?.isSubscription ? item.description.toUpperCase() : `Vehicle Rental Charges (${item.description.toUpperCase()})`)
                                : item.type === IInvoiceItemType.DEPOSIT
                                  ? `Deposit (${item.description.toUpperCase()}) (Refundable)`
                                  : item.type === IInvoiceItemType.INSURANCE
                                    ? `Insurance (${item.description.toUpperCase()})`
                                    : item.type ===
                                      IInvoiceItemType.LOCATION_SURCHARGE
                                      ? `Location Surcharge (${item.description.toUpperCase()})`
                                      : item.type === IInvoiceItemType.ADDON
                                        ? `${item.description.toUpperCase()}`
                                        : item.description.toUpperCase()}{" "}
                              <br />
                              {invoice.invoiceType === InvoiceType.SUNDRY ? (
                                <>
                                  {rentalTaxDetails && rentalTaxDetails.length > 0 && rentalTaxDetails.map(title => {
                                    return (
                                      <>
                                        <span>({title})</span><br></br>
                                      </>
                                    )
                                  })}
                                </>
                              ) : (
                                <>
                                  {item.taxDescription &&
                                    item.taxDescription.length > 0 &&
                                    item.taxDescription.map((title) => {
                                      return (
                                        <>
                                          <span>({title})</span>
                                          <br></br>
                                        </>
                                      );
                                    })}
                                </>
                              )}
                            </StyledTableCell>
                            <StyledTableCell>{item.quantity}</StyledTableCell>
                            <StyledTableCell>
                              {toCurrency(item.unitPrice, currency, locale)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.duration
                                ? item.isProrated
                                  ? `${item.duration} ${getRateTypeString(
                                    rateTypeName
                                  )} (${item.proratedBillingDays
                                  } days pro-rated)`
                                  : `${item.duration} ${getRateTypeString(
                                    rateTypeName
                                  )}`
                                : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {toCurrency(taxAmount, currency, locale)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {toCurrency(
                                (item.unitPrice * multiplier) + (item.taxAmount ? item.taxAmount : 0),
                                currency,
                                locale
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                    {invoice.discountAmount > 0 && (
                      <StyledTableRow>
                        <StyledTableCell colSpan={4}></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body1">{"Discount"}</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          {"-"}
                          {toCurrency(invoice.discountAmount, currency, locale)}
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    <StyledTableRow>
                      <StyledTableCell colSpan={4}></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1">{"Total"}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {toCurrency(
                          invoice.totalPayableAmount,
                          currency,
                          locale
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell colSpan={4}></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1">{"Paid"}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {toCurrency(
                          invoice.confirmedPaidAmount,
                          currency,
                          locale
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell colSpan={2}>
                        {invoice.booking?.taxExempted ?
                          <Typography variant="h4">{`*This is a ${getLocalizedTaxSyntex(country)} exempted ${getLocalizedBookingSyntex(country).toLowerCase()}.`}</Typography> :
                          <Typography></Typography>
                        }
                        {invoice.invoiceType !== InvoiceType.SUNDRY && invoice.booking?.zeroCostBooking ?
                          <Typography variant="h4">*This is a test/courtesy {getLocalizedBookingSyntex(country).toLowerCase()}.</Typography> :
                          <Typography></Typography>
                        }
                      </StyledTableCell>
                      <StyledTableCell colSpan={2}></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="h4">{"Balance Due"}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {toCurrency(invoice.dueAmount, currency, locale)}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Grid>
        {invoice.invoiceType !== InvoiceType.SUNDRY && invoice.booking?.zeroCostBookingNote && (
          <Grid item xs={12}>
            <Typography variant={"h3"}>
              {"Additional Note :"}
            </Typography>
            <span style={{ fontSize: 14, fontFamily: '"Amazon Ember", "Helvetica Neue Light", "Helvetica" , "Arial", "sans-serif"' }}>{invoice.booking.zeroCostBookingNote}</span>
          </Grid>
        )}
        {invoice.invoiceType === InvoiceType.CONSOLIDATED && (
          <Grid item xs={12}>
            <div className={`${styles.box}`}>
              <div className="font-medium semi-bold margin-bottom padding-bottom">
                Invoices Consolidated
              </div>
              <div style={{ margin: "0 -10px -10px -10px" }}>
                <TableContainer style={{ maxHeight: "100%" }}>
                  <Table stickyHeader aria-label="customized table">
                    <TableHead>
                      <StyledTableRow>
                        {consolidatedInvoicesColumns.map((column: string) => {
                          return <StyledTableCell>{column}</StyledTableCell>;
                        })}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {invoice.invoices && invoice.invoices.map((item, index) => {  
                        return (
                          <StyledTableRow key={index} onClick={() => history.push(`/account/invoices/${item.id}`)}>
                            <StyledTableCell>
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell className={styles.consolidatedInvoiceRow}>
                              {item.invoiceRef}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Grid>
        )}
        {invoice.payments.length >0 &&
        invoice.payments.some((item)=>item.amount>0) && ( 
        <Grid item xs={12}>
          <div className={`${styles.box}`}>
            <div className="font-medium semi-bold margin-bottom padding-bottom">
              Payments
            </div>
            <div style={{ margin: "0 -10px -10px -10px" }}>
              <TableContainer style={{ maxHeight: "100%" }}>
                <Table stickyHeader aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      {paymentColumns.map((column: string) => {
                        return <StyledTableCell>{column}</StyledTableCell>;
                      })}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.payments && 
                    invoice.payments
                    .filter((item) => item.amount > 0 )
                    .map((item, index) => {  
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {getLocalizedDateFormat(country, item.dateCreated, DATE_TYPE.CONDENSED,invoice.booking?.branch?.timeZone)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {toCurrency(item.amount, currency, locale)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {item.paymentMode} ({item.paymentGateway})
                          </StyledTableCell>
                          <StyledTableCell>{item.status}</StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Grid> )}
        {invoice?.adjustedCreditNotes && invoice.adjustedCreditNotes.length >0 &&
        invoice.adjustedCreditNotes.some((item)=>item.amount>0) && ( 
        <Grid item xs={12}>
          <div className={`${styles.box}`}>
            <div className="font-medium semi-bold margin-bottom padding-bottom">
              Credits
            </div>
            <div style={{ margin: "0 -10px -10px -10px" }}>
              <TableContainer style={{ maxHeight: "100%" }}>
                <Table stickyHeader aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Credit Note Id</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.adjustedCreditNotes && 
                    invoice.adjustedCreditNotes
                    .filter((item) => item.amount > 0 )
                    .map((item, index) => {  
                      return (
                        <StyledTableRow key={index} onClick={() => history.push(`/account/credit-notes/${item.creditNote.id}`)}>
                          <StyledTableCell>
                            {getLocalizedDateFormat(country, item.adjustedAt, DATE_TYPE.CONDENSED,invoice.booking.branch?.timeZone)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {toCurrency(item.amount, currency, locale)}
                          </StyledTableCell>
                          <StyledTableCell>
                            <span
                              style={{
                                color: "rgb(24, 117, 210)",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              {item.creditNote.creditNoteRef}
                            </span>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Grid> )}
      </Grid>
      <Grid container></Grid>
    </div>
  );
};

export default Invoice;
