import { gql } from "apollo-boost";

export const GET_VEHICLE_GROUP_CRITERIA = gql`
  query consumerVehicleGroupCriteria ($branchId : String!) {
    consumerVehicleGroupCriteria (branchId : $branchId) {
      type
      values
    }
  }
`;

export const GET_EXTERNAL_VEHICLE_GROUP_CRITERIA = gql`
  query consumerExternalVehicleGroupCriteria ($branchId : String!) {
    consumerExternalVehicleGroupCriteria (branchId : $branchId) {
      type
      values
    }
  }
`;
