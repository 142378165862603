import React from "react"
import styles from "./index.module.css";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import MapIcon from '@material-ui/icons/Map';
import PeopleIcon from '@material-ui/icons/People';
import { NavLink } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { AccountType, UserRole } from '../../../../reducers/auth/types';
import { getLocalizedBookingSyntex } from "../../../../utils/localized.syntex";

const SideBar = () => {
  const authState = useSelector((state: IAppState) => state.authReducer);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);;
  const { country } = website.organisation.address;

  if (!user) {
    return <>Please Login first</>
  }
  return (
    <>
      <Hidden xsDown>
        <div className={styles.column}>
          <NavLink to="/account" exact activeClassName={styles.active} className={styles.navItem}>
            <AccountCircleIcon fontSize="inherit" />
            <span>Account</span>
          </NavLink>
          {(authState.accountType === AccountType.INDIVIDUAL || (authState.accountType === AccountType.BUSINESS && user.role !== UserRole.DRIVER)) && <NavLink to="/account/bookings" activeClassName={styles.active} className={`${styles.navItem}`}>
            <CheckCircleIcon fontSize="inherit" />
            <span>{`${getLocalizedBookingSyntex(country)}s`}</span>
          </NavLink>}
          {!website.organisation.closeGroupSharingEnabled && (authState.accountType === AccountType.INDIVIDUAL || (authState.accountType === AccountType.BUSINESS && user.role !== UserRole.DRIVER)) && <NavLink to="/account/invoices" activeClassName={styles.active} className={styles.navItem}>
            <CreditCardIcon fontSize="inherit" />
            <span>Invoices</span>
          </NavLink>}
          {!website.organisation.closeGroupSharingEnabled && (authState.accountType === AccountType.INDIVIDUAL || (authState.accountType === AccountType.BUSINESS && user.role !== UserRole.DRIVER)) && <NavLink to="/account/credit-notes" activeClassName={styles.active} className={styles.navItem}>
            <CreditCardIcon fontSize="inherit" />
            <span>Credit Notes</span>
          </NavLink>}
          {user.businesscustomers && <>
            <NavLink to="/account/vehicles" activeClassName={styles.active} className={styles.navItem}>
              <DriveEtaIcon fontSize="inherit" />
              <span>Vehicles</span>
            </NavLink>
            {user.role === UserRole.ADMIN_USER && <><NavLink to="/account/drivers" activeClassName={styles.active} className={styles.navItem}>
              <PeopleIcon fontSize="inherit" />
              <span>Drivers</span>
            </NavLink>
              <NavLink to="/account/tracking" activeClassName={styles.active} className={styles.navItem}>
                <MapIcon fontSize="inherit" />
                <span>Tracking</span>
              </NavLink>
            </>}
          </>
          }
        </div>
      </Hidden>
    </>
  )
}

export default SideBar