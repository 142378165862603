import dayjs from "dayjs";

export function capitalizeFirstLetter(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export enum FleetTypes {
  OWN = "OWN",
  SHARED = "SHARED"
}

export const rateTypes = [
  {
    rateTypeName: "hourly",
    rateTypeDuration: 60
  },
  {
    rateTypeName: "daily",
    rateTypeDuration: 1440
  },
  {
    rateTypeName: "weekly",
    rateTypeDuration: 10080
  },
  {
    rateTypeName: "monthly",
    rateTypeDuration: 43200
  }
];

export const billingCycles = [
  {
    billingCycleName: "none"
  },
  {
    billingCycleName: "weekly",
    billingCycleDuration: 10080
  },
  {
    billingCycleName: "monthly",
    billingCycleDuration: 43200
  }
];

export const customerTypes: any = [
  {
    value: "individual",
    label: "Individual"
  },
  {
    value: "business",
    label: "Business"
  }
];
export function getRentalPeriod(
  startDate: string,
  endDate: string,
  rateTypeDuration: number,
  rateTypeName: string
): string {
  let rentalPeriod = "";
  const timeDiff: number = dayjs(endDate).diff(startDate);
  const rentalTime: number = Math.ceil(timeDiff / (rateTypeDuration * 60000));
  rentalPeriod = rentalTime + " ";
  switch (rateTypeName) {
    case "hourly":
      rentalPeriod += "hour(s)";
      break;
    case "daily":
      rentalPeriod += "day(s)";
      break;
    case "weekly":
      rentalPeriod += "week(s)";
      break;
    case "monthly":
      rentalPeriod += "month(s)";
      break;
  }
  return rentalPeriod;
}

export enum Constants {
  MINUTE_TO_MILLISECOND = 60000,
  BOOKING_ADVANCE_START_TIME = 5 // in minutes
}

export enum BookingFlowStage {
  BASE_DETAILS,
  ADDONS_SELECTION,
  PERSONAL_DETAILS,
  QUOTE_VIEW,
  PAYMENT_VIEW
}

export const addonTypes = ["INSURANCE", "CUSTOM", "VALET"];

export enum BookingStatus {
  IN_PROGRESS = "IN_PROGRESS"
}

// copied from view/fleet/vehiclePriceGroups/utils from original

export const initialBasePrices = [
  {
    rateTypeName: "hourly",
    rate: 0,
    rateTypeDuration: 60,
    longTerm: false,
    mileageLimit: 0,
    pricePerExtraMile: 0
  },
  {
    rateTypeName: "daily",
    rate: 0,
    rateTypeDuration: 1440,
    longTerm: false,
    mileageLimit: 0,
    pricePerExtraMile: 0
  },
  {
    rateTypeName: "weekly",
    rate: 0,
    rateTypeDuration: 10080,
    longTerm: false,
    mileageLimit: 0,
    pricePerExtraMile: 0
  },
  {
    rateTypeName: "monthly",
    rate: 0,
    rateTypeDuration: 43200,
    longTerm: false,
    mileageLimit: 0,
    pricePerExtraMile: 0
  }
];
