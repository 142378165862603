// import { IServiceLocation } from "../user/types";

import { PaymentGateway } from "../bookings/types";

export interface IConsumerWebsiteState {
  consumerWebsite: IConsumerWebsite;
}

export type WebsitePayloadType = IConsumerWebsite | IConsumerWebsiteId;

export interface IConsumerWebsiteId {
  id?: string;
}
export interface IConsumerWebsite {
  id?: string;
  clientId?: string;
  appName: string;
  domain: string;
  websiteTitle: string;
  websiteIcon: string;
  websiteLogo: string;
  websiteHeader: string;
  hasCarousel: boolean;
  hasGallery: boolean;
  branding: IBranding;
  organisationId: string;
  tenantId: string;
  branchIds: string[];
  bookingQuestions: IBookingQuestion[];
  websiteDescription: string;
  carousel: string[];
  gallery: string[];
  privacyPolicyURL: string;
  termsAndConditionsURL: string;
  branches: IBranch[];
  organisation: IOrganisation;
  tenancy: ITenancy;
  logoSize: number;
  htmlDescription: boolean;
  companyWebsite?: string;
  googleGTMId?: string;
  allowedRateTypes?: string[];
  allowedBillingFrequencies?: string[];
  enableBusinessCustomerSignup: boolean;
  paymentGateway?: PaymentGateway;
  bookingTypes: string[];
}
export interface IAppSetting {
  id?: string;
  appName: string;
  organisationId: string;
  branchIds: string[];
  privacyPolicyURL: string;
  provider?: string;
  callbackUris?: string;
  clientId?: string;
  clientSecret?: string;
  firstParty?: boolean;
  companyWebsite: string;
  termsAndConditionsURL: string;
  branding: IBranding;
  isActive: boolean;
  webAppSetting: IWebSetting;
  organisation?: Partial<IOrganisation>;
  tenancy?: Partial<ITenancy>;
  tenantId: string;
  mobileAppSetting: IMobileAppSetting;
  paymentGateway?: PaymentGateway;
}

export interface IWebSetting {
  websiteTitle: string;
  websiteIcon: string;
  websiteLogo: string;
  domain: string;
  websiteHeader: string;
  websiteDescription: string;
  websiteAddress: string;
  logoSize: number;
  htmlDescription: boolean;
  hasCarousel: boolean;
  carousel: string[];
  hasGallery: boolean;
  gallery: string[];
  googleGTMId: string;
  enableBusinessCustomerSignup: boolean;
}

export interface IMobileAppSetting {
  appIcon: string;
  companyIcon: string;
  straplineIcon: string;
  landingPageIcon: string;
}

export interface IBranding {
  buttonColor: string;
  accentColor: string;
}
export interface IBookingQuestion {
  question: string;
  answerType: ANSWER_TYPES;
  required: boolean;
  expectedAnswer?: string;
  options?: string[];
  errorMessage?: string;
  B2B?: boolean;
  B2B2C?: boolean;
}
export enum ANSWER_TYPES {
  YES_NO = "YES_NO",
  DROP_DOWN = "DROP_DOWN",
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX",
}
export interface IB2b2cSettings {
  active: boolean;
  carousels: string[];
  vehicles: string[];
  description: string;
  privacyPolicyURL: string;
  websiteTitle: string;
  favicon: string;
}
export interface IOrganisation {
  id: string;
  address: IAddress;
  locale: string;
  owner: string;
  emailId: string;
  name: string;
  phoneNumber: IPhoneNumberInput;
  termsAndConditionsUrl: any;
  currency: string;
  logoUrl: any;
  rate: any;
  stripeAccountId?: string;
  orgVatNumber: string;
  orgRegNumber: string;
  taxes: ITax[];
  branches: IBranch[];
  autoRateType?: boolean;
  requiresCustomerVerification: boolean;
  allowSubscriptionBookings?: boolean;
  allowCarSharing?: boolean;
  autoChargeEnabled?: boolean;
  closeGroupSharingEnabled?: boolean;
  enableCostCenter?: boolean;
  crossLocationBookingEnabled?: boolean;
  costCenters?: ICostCenter[];
  maxImagesPerDamage?: number;
}

export interface ICostCenter {
  name: string;
  projectIds: string[];
}
export interface IPhoneNumberInput {
  phone: string;
  country: string;
}
export interface ITenancy {
  id?: string;
  owner?: IOwner;
  chargebeeAddons?: string[];
  loginEnabled?: boolean;
}

export interface IOwner {
  id: string;
  userPoolId: string;
  identityPoolId: string;
}

export interface IBilling {
  name: string;
  billingAddressSame: boolean;
  address: IAddress;
  emailId: string;
  phoneNumber: IPhoneNumberInput;
}

export interface IOrganisationId {
  id: string;
}

export interface IBranch {
  id: string;
  address: IAddress;
  owner: string;
  emailId: string;
  timeZone: string;
  name: string;
  phoneNumber: IPhoneNumberInput;
  locations: IServiceLocation[];
  operatingHours: IOperatingHours[];
  minBookingDuration?: number;
  minimumBookingAmount: IMinimumBookingAmount;
  depositCollection?: string;
  minBookingAmountOptIn: boolean;
  minBookingDurationOptIn?: boolean;
  overbookingAllowed: boolean;
  preBookingQuestions: IBookingQuestion[];
  distanceUnit: string;
  holidays: IHoliday[];
  addTaxOptIn: boolean;
  taxes: ITax[];
}

export interface IHoliday {
  id?: string;
  name: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
}

export interface IMinimumBookingAmount {
  type: string;
  value: number;
}
export interface IOperatingHours {
  day: string;
  dayIndex: number;
  pickupFromTime?: string;
  pickupToTime?: string;
  returnFromTime?: string;
  returnToTime?: string;
  closed: boolean;
}

export interface IBranchId {
  id: string;
}

export interface IServiceLocation {
  id: string;
  address: IAddress;
  name: string;
  branch?: string | Partial<IBranch>;
}

export interface IServiceLocationId {
  id: string;
}

export interface ITax {
  id: string;
  title: string;
  rate: string;
  type: string;
  value: number;
  subdivisionOptIn: boolean;
  subdivisionType: string;
  subdivisionRate: string;
  subdivisionValue: number;
}

export interface IAddress {
  street?: string;
  city?: string;
  state?: string;
  country: string;
  zipcode?: string;
  fullAddress?: string;
  geometry?: IGeometry;
}

export interface IGeometry {
  type: string;
  latitude: number;
  longitude: number;
}
