import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { ISubscriptionArgs, ISubscriptionInfo, IVehicle, IVehiclePriceGroup } from '../../../../reducers/bookings/types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Card, CardActions, CardContent, CardHeader, CardMedia, Fab, Grid, IconButton, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { getFeatureTypeIcon, getVehicleTypeIcon, toCurrency } from '../../../utils';
import { RATE_TYPES, capitalizeFirstLetter } from '../../utils';
import SelectSubscriptionPricingView from './SelectSubscriptionPricingView';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { Storage } from "aws-amplify";
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import { getSignedUrl } from '../../../../utils/getSignedUrl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 0, // 16:9
      backgroundSize: "contain",
      paddingTop: "25%",
      marginTop: "2rem"
    },
    cardHeader: {
      background: "#55c2da",
      color: "#fff",
      textAlign: "center"
    },
    actionBtn: {
      backgroundImage: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)"
    },
    subButn: {
      background: "none",
      border: "none",
      boxShadow: "none",
      fontSize: 16,
      color: "#fff",
      width: "100%",
      height: 0,
      "&:hover": {
        background: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)",
      }
    }
  }),
);

interface IProps {
  subscriptionVehicles: IVehicle[];
  bookingDurationInMonths: number;
  bookingDurationInWeeks: number;
  onSelectSubscription: (subscription: ISubscriptionArgs, vehicle: IVehicle) => void;
}

const SelectSubscriptionVehicleView: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const snackbar = useSnackBar();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle>();
  const [vehicleImages, setVehicleImages] = useState<any>([]);
  const [noVehiclesFoundError, setNoVehiclesFoundError] = useState<boolean>(false)

  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { locale, currency } = website.organisation

  useEffect(() => {
    if (props.subscriptionVehicles && props.subscriptionVehicles.length) {
      getVehicleImage(props.subscriptionVehicles)
    }
  }, [props.subscriptionVehicles])

  const getVehicleImage = (vehicles: IVehicle[]) => {
    vehicles.map(async (vehicle: any) => {
      try {
        if (vehicle && vehicle.imageUrl) {
          const config = { contentType: "image/*", level: "public" };
          const file = await Storage.get(vehicle.imageUrl, config).then((result) => {
            return result;
          });
          const response = await fetch(file as string).then((res) => {
            return res;
          });
          if (response.ok) {
            setVehicleImages([...vehicleImages, {
              vehicleId: vehicle.id,
              imageUrl: file
            }]);
          }
        }
      } catch (error) {
        snackbar({
          message: "Invalid File Type. Allowed type: jpeg or png",
          variant: SnackBarVariant.ERROR
        });
      }
    });
  };

  const getLowestSubscriptionPricing = (vehicles: IVehicle[]) => {
    const subscriptions: ISubscriptionInfo[] = [];
    vehicles.forEach(vehicle => {
      if (vehicle.subscriptions?.length) {
        subscriptions.push(...vehicle.subscriptions)
      }
    })
    let pricings: any[] = [];
    subscriptions.forEach(subscription => {
      if (subscription.subscription.isActive) {
        subscription.pricings.forEach(pricing => {
          if ((subscription.subscription.rateTypeName === RATE_TYPES.MONTHLY && pricing.duration <= props.bookingDurationInMonths) || (subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY && pricing.duration <= props.bookingDurationInWeeks)) {
            pricings.push(pricing)
          }
        })
      }
    })
    
    const lowestPricing = Math.min(...pricings.map(({ subscriptionAmount }) => subscriptionAmount));
    return lowestPricing
  }

  return (
    <Grid item xs={12} container spacing={2}>
      {
        props.subscriptionVehicles && props.subscriptionVehicles.length > 0 &&
        props.subscriptionVehicles.map((vehicle: IVehicle, index: number) => {
          if (vehicleImages && vehicleImages.length) {
            vehicleImages.find((vehicleImage: any, index: number) => {
              if (vehicleImage.vehicleId === vehicle.id) {
                vehicle.imageUrl = vehicleImage.imageUrl;
              }
            });
          }
          if (vehicle && vehicle.subscriptions.length) {
            const lowestPrice = getLowestSubscriptionPricing([vehicle])
            if (lowestPrice !== Infinity) {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <Card 
                    style={{borderRadius: 14}}
                  >
                    <CardHeader
                      className={classes.cardHeader}
                      action={
                        <IconButton aria-label="settings">

                        </IconButton>
                      }
                      title={<Typography variant='h4' style={{ fontSize: 20 }}>{`${vehicle.make} ${vehicle.model}`}</Typography>}
                      subheader={<Typography variant='caption'></Typography>}
                    />
                    <CardContent>
                      <Grid item xs={12} container justify='center'>
                        {
                          vehicle.imageUrl ? (

                            <img
                              src={vehicle.imageUrl}
                              alt='vehicle'
                              style={{ width: "100%", height: "200px" }}
                            />
                          ) : (
                            <img
                              src={getVehicleTypeIcon(capitalizeFirstLetter(vehicle.bodyType))}
                              alt='vehicle'
                              style={{ width: "100%", height: "200px" }}
                            />
                          )
                        }
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                          <img
                            style={{
                              height: "12px",
                              marginRight: "10px",
                              width: "16px",
                            }}
                            src={getFeatureTypeIcon("numberOfDoors")}
                            alt={"numberOfDoors"}
                          />
                          <span style={{ fontSize: 12 }}>{`${vehicle.numberOfDoors} Doors`}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <img
                            style={{
                              height: "12px",
                              marginRight: "10px",
                              width: "16px",
                            }}
                            src={getFeatureTypeIcon("numberOfSeats")}
                            alt={"numberOfSeats"}
                          />
                          <span style={{ fontSize: 12 }}>{`${vehicle.numberOfSeats} Seats`}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <img
                            style={{
                              height: "12px",
                              marginRight: "10px",
                              width: "16px",
                            }}
                            src={getFeatureTypeIcon("transmission")}
                            alt={"transmission"}
                          />
                          <span style={{ fontSize: 12 }}>{`${vehicle.transmission}`}</span>
                        </Grid>
                        <Grid item xs={6}>
                          <img
                            style={{
                              height: "12px",
                              marginRight: "10px",
                              width: "16px",
                            }}
                            src={getFeatureTypeIcon("fuelType")}
                            alt={"fuelType"}
                          />
                          <span style={{ fontSize: 12 }}>{`${vehicle.fuelType}`}</span>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions className={classes.actionBtn} disableSpacing>
                      <Grid item xs={12} container justify='center'>
                        <Fab
                          className={`${classes.subButn} test `}
                          onClick={() => {
                            setSelectedVehicle(vehicle)
                            setOpen(true)
                          }}
                        >
                          {
                            props.bookingDurationInMonths > 0 && vehicle.subscriptions.some((subscription) => subscription.pricings.some(pricing => (subscription.subscription.rateTypeName === RATE_TYPES.MONTHLY && pricing.duration <= props.bookingDurationInMonths) || (subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY && pricing.duration <= props.bookingDurationInWeeks))) && (
                              <Typography variant="h3" style={{ fontWeight: 600 }}>Subscription starts at {toCurrency(lowestPrice, currency, locale)}</Typography>
                            )
                          }
                        </Fab>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              )
            }
          }
        })
      }
      {noVehiclesFoundError && <div className={styles.vehicleGroupcard} style={{ padding: 15, border: 0 }}>No vehicles found with matching criteria!</div>}
      {
        open && (
          <SelectSubscriptionPricingView
            open={open}
            bookingDurationInMonths={props.bookingDurationInMonths || 0}
            bookingDurationInWeeks={props.bookingDurationInWeeks || 0}
            onCancel={() => setOpen(false)}
            setOpen={setOpen}
            onSelectSubscription={props.onSelectSubscription}
            vehicle={selectedVehicle}
          />
        )
      }
    </Grid>
  )
}

export default SelectSubscriptionVehicleView
