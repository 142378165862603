import { Dialog, DialogTitle, Grid, Typography, IconButton, DialogContent, List, ListItem, ListItemText, Tooltip, Fab, Slide, Theme, createStyles, makeStyles } from '@material-ui/core'
import { locale } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { IAddons, ISubscription, ISubscriptionArgs, ISubscriptionPricing, IVehicle, IVehiclePriceGroup } from '../../../../reducers/bookings/types'
import { getVehicleTypeIcon, toCurrency } from '../../../utils'
import { NuvvenDivider } from '../../Summary/Components/Divider'
import { RATE_TYPES } from '../../utils'
import { capitalizeFirstLetter } from '../utils'
import { TransitionProps } from '@material-ui/core/transitions'
import { useSelector } from 'react-redux'
import { IAppState } from '../../../../store'
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(3)
    },
    iconStyle: {
      padding: 2
    },
    avatarStyle: {
      background: "var(--theme-accent)",
      height: 25,
      width: 25
    },
    paperStyle: {
      flexGrow: 1
    }
  })
);

interface IProps {
  open: boolean;
  onSelectSubscription(selectedSubscription: ISubscriptionArgs, vehicle?: IVehicle): void;
  onCancel(): void;
  vehicle?: IVehicle;
  bookingDurationInMonths: number;
  bookingDurationInWeeks: number;
  setOpen(open: boolean): void
}

interface ISelectSubscriptionPricing {
  subscription: ISubscription;
  pricing: ISubscriptionPricing;
  deposit: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectSubscriptionPricingView: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { locale, currency } = website.organisation

  const [subscriptionPlans, setSubscriptionPlans] = useState<ISelectSubscriptionPricing[]>([])

  useEffect(() => {
    if (props.vehicle && props.vehicle.subscriptions?.length) {
      const plans: ISelectSubscriptionPricing[] = []
      props.vehicle.subscriptions.forEach(subscription => {
        if (subscription.subscription.isActive) {
          let pricings = subscription.pricings || []
          pricings = pricings.sort((a, b) => b.duration - a.duration)
          if (pricings.length) {
            const pricing = pricings.find((pricing) => (subscription.subscription.rateTypeName === RATE_TYPES.MONTHLY && pricing.duration <= props.bookingDurationInMonths) || (subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY && pricing.duration <= props.bookingDurationInWeeks))
            if (pricing) {
              plans.push({
                subscription: subscription.subscription as any,
                pricing,
                deposit: subscription.deposit
              })
            }
          }
        }
      });
      setSubscriptionPlans(plans)
    }
  }, [props.vehicle])

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onCancel}
      aria-labelledby="select-subscription-dialog"
      aria-describedby="pricing-edit-fields"
      maxWidth={"md"}
      style={{ flexGrow: 1 }}
      classes={{
        paper: classes.paperStyle
      }}
    >
      <DialogTitle style={{ paddingBottom: 5 }} id="select-subscription-dialog">
        <Grid item xs={12} container justify='space-between' alignItems='baseline'>
          <Grid><Typography style={{ fontWeight: 600 }} color='primary' variant='h2'>SELECT SUBSCRIPTION</Typography></Grid>
          <Grid>
            <IconButton
              onClick={props.onCancel}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <NuvvenDivider noMargin />
      <DialogContent>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}><Typography style={{ fontWeight: 600 }} variant='h3'>{`${props.vehicle?.make} ${props.vehicle?.model}`}</Typography></Grid>
          {props.vehicle?.imageUrl ? (
            <img
              className="fleet-resrc-img"
              alt="vehicle type icon"
              src={props.vehicle.imageUrl}
              height="90"
              width="180"
              style={{ width: "40%", height: "40%", borderRadius: 4 }}
            />
          ) : (
            <img
              className="fleet-resrc-img"
              alt="vehicle type icon"
              src={getVehicleTypeIcon(
                capitalizeFirstLetter(props.vehicle?.bodyType || "")
              )}
              style={{ width: "40%", height: "40%", borderRadius: 4 }}
            />
          )}
          <Grid item xs={12}><Typography variant='h4'>*(Prices shown is based on selected duration)</Typography></Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div><Typography variant='h2'>Price Comparison</Typography></div>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={3}>
                <List>
                  <ListItem disableGutters style={{ paddingTop: 16, paddingBottom: 16 }}>
                    <ListItemText primary={<Typography variant='h4'></Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Subscription price</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Duration(Minimum)</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Enrollment fee(One time)</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Deposit(Refundable)</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Mileage</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Price per extra mile</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Swaps Allowed</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h3'>Included</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Addon(s)</Typography>} />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText primary={<Typography variant='h4'>Insurance</Typography>} />
                  </ListItem>
                </List>

              </Grid>
              {
                subscriptionPlans && subscriptionPlans.length > 0 &&
                subscriptionPlans.map(subscription => {
                  return <>
                    <Grid item xs={3}>
                      <List>
                        <ListItem disableGutters>
                          <ListItemText primary={<Typography variant='h4'>{subscription.subscription.name}</Typography>} />
                        </ListItem>
                        <ListItem disableGutters>
                        <ListItemText primary={<Typography variant='h4'>{`${toCurrency(subscription.pricing.subscriptionAmount, currency, locale)} per ${subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? "week" : "month"}`}</Typography>} />
                        </ListItem>
                        <ListItem disableGutters>
                        <ListItemText primary={<Typography variant='h4'>{`${subscription.pricing.duration} ${subscription.subscription.rateTypeName === RATE_TYPES.WEEKLY ? "Weeks" : "Months"}`}</Typography>} />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText primary={<Typography variant='h4'>{toCurrency(subscription.pricing.enrollmentAmount, currency, locale)}</Typography>} />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText primary={<Typography variant='h4'>{subscription.deposit ? toCurrency(subscription.deposit, currency, locale) : "N/A"}</Typography>} />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText primary={<Typography variant='h4'>{subscription.pricing.unlimitedMileage ? "Unlimited" : subscription.pricing.mileage}</Typography>} />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText primary={<Typography variant='h4'>{subscription.pricing.unlimitedMileage ? "N/A" : toCurrency(subscription.pricing.pricePerExtraMile, currency, locale)}</Typography>} />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText primary={<Typography variant='h4'>{subscription.pricing.swapsAllowed || "N/A"}</Typography>} />
                        </ListItem>
                        <ListItem disableGutters style={{ paddingTop: 20, paddingBottom: 20 }}>
                          <ListItemText primary={<Typography variant='h3'></Typography>} />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText primary={
                            <div style={{ display: "flex" }}>
                              {
                                subscription?.subscription?.addons?.length > 0 ?

                                  (
                                    <>
                                      <Typography variant='h4'>
                                        {subscription.subscription.addons[0].displayName}
                                      </Typography>
                                      <Tooltip
                                        title={<>
                                          <div style={{ color: "#fff;" }}>Other Addons:</div>
                                          {
                                            subscription.subscription.addons.map((addon: IAddons, index: number) => {
                                              return (<>
                                                <div>
                                                  {`${index + 1}. ${addon.displayName}`} <br />
                                                </div>
                                              </>)
                                            })
                                          }
                                        </>}
                                        arrow
                                        placement={"right"}
                                      >
                                        <div style={{ marginLeft: "1rem" }}><InfoIcon fontSize='small' /></div>
                                      </Tooltip>
                                    </>
                                  )
                                  : "-"
                              }
                            </div>
                          } />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText primary={<Typography variant='h4'>
                            {
                              subscription?.subscription?.insurancePolicy ? subscription.subscription.insurancePolicy.name : "-"
                            }
                          </Typography>} />
                        </ListItem>
                      </List>
                      <Fab
                        size="medium"
                        variant="extended"
                        onClick={() => {
                          props.setOpen(false)
                          props.onSelectSubscription({ pricing: subscription.pricing, subscription: subscription.subscription, deposit: subscription.deposit }, props.vehicle)
                        }}
                      >
                        Select
                      </Fab>
                    </Grid>
                  </>
                })
              }
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default SelectSubscriptionPricingView
