import React from "react";
import CarouselModule from '../CarouselModule/CarouselModule'
import GalleryModule from '../VehicleGallery/GalleryModule'
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import styles from './index.module.css';
import Container from '@material-ui/core/Container';
interface IProps {
  setLocation: (locationId: string, branchId: string) => void;
  showCarousel: boolean;
}

const PageModules: React.FC<IProps> = (props) => {
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const carousel = website.hasCarousel ? website.carousel : []
  const vehiclesGallery = website.hasGallery ? website.gallery : []
  const description = website.websiteDescription || '';
  const htmlDescription = website.htmlDescription || false
  return (
    <div className={styles.pageModule}>
      {props.showCarousel && description.length ? <div className={styles.moduleWrap}>
        <Container>
          {htmlDescription ? <div style={{ textAlign: "initial" }} dangerouslySetInnerHTML={{ __html: description }}></div> : <h4 style={{ padding: "0 30px", fontWeight: "initial", margin: 0 }}>{description}</h4>}
        </Container>
      </div> : null}
      {props.showCarousel && website.hasCarousel && carousel.length ? <CarouselModule /> : null}
      {/* <LocationModule setLocation={props.setLocation} /> */}
      {website.hasGallery && vehiclesGallery.length ? <GalleryModule /> : null}
    </div>
  )
}

export default PageModules