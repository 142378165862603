import React, { useEffect, useState } from 'react';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import styles from './index.module.css';
import { CircularProgress, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Slide, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RefreshIcon from '@material-ui/icons/Refresh';
import CachedIcon from '@material-ui/icons/Cached';
import api from "../Api"
import { useLazyQuery } from '@apollo/client';
import { ApolloError } from 'apollo-boost';
import dayjs from 'dayjs';
import { useSnackBar } from '../../../../SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../../../SnackbarWrapper/SnackbarWrapper';
import { GET_VEHICLE_DATA } from '../../../../../graphql/vehiclePriceGroup/getVehicleGroupTrackingQuery';
import { getSignedUrl } from '../../../../../utils/getSignedUrl';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../../store';
import { getLocalizedDateFormat } from '../../../../../utils/localized.syntex';
import { DATE_TYPE } from '../../../../utils';

interface IProps {
  active: boolean;
  handleClose: () => void;
  setData: (data?: ITelematicsData | undefined) => void;
  smartcarData: ISmartcarData[] | undefined;
  data?: ITelematicsData;
  esn: string;
  vehicleId: string;
  selectedEvents: string[];
  handleSelectEvents: (selectedEvents: string[]) => void;
  vehicleLastLocation: string;
  lastLocationTime: string;
}

export const eventKeysMap: { [key: string]: { display: string, color: string } } = {
  "speeding": {
    display: "Speeding",
    color: "#0EB000"
  },
  "hardCornering": {
    display: "Hard-Corner",
    color: "#7300FF"
  },
  "harshAcceleration": {
    display: "Harsh-Accel.",
    color: "#CF9F00"
  },
  "harshBraking": {
    display: "Harsh-Brake",
    color: "#F05821"
  },
  "lostGsm": {
    display: "Lost-GSM",
    color: "#747474"
  },
  "lostGps": {
    display: "Lost-GPS",
    color: "teal"
  }

}

export interface ITelematicsData {
  milesDriven: string;
  engineHours: string;
  fuelUsage: string;
  stoppageHours: string;
  mapFlagsData: {
    eventTime: string[];
    speedLimit: string[];
    magnitude: string[];
    Key: string[];
    latLong: string[];
  },
  mapRoutesData: IMapRouteData
}

export interface ISmartcarData {
  id: string;
  latitude?: number;
  longitude?: number;
  engineOilLife?: number;
  evBatteryLevel?: number;
  evChargeStatus?: string;
  fuelLevel?: number;
  odometer?: number;
}

export interface IMapRouteData {
  eventTime: string[];
  latLong: string[];
}

export interface IVehicleData {
  cylinderCapacity: string;
  fuelType: string;
  licencePlate: string;
  telemetryDeviceId: string;
  imageUrl: string;
  id: string;
  model: string;
  make: string;
}

const TelematicsViewComponent: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const [vehicleData, setVehicleData] = useState<IVehicleData | undefined>();
  const [vehicleImage, setVehicleImage] = useState<any>("");
  const [trackingHistory, setTrackingHistory] = useState<number>(2);
  const [smartcarVehicle, setSmartcarVehicle] = useState<ISmartcarData>();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const branchData = website.branches
  const { country } = website.organisation.address;
  const [currentTimeZone, setCurrentTimeZone] = useState<string>("Europe/london");

  const setTimeZone = (branchId: string) => {
    const branch: any = branchData.find(singlebranch => singlebranch.id === branchId);
    if (branch) {
      setCurrentTimeZone(branch.timeZone);
    }
  };

  const getVehicleImage = async (url: string) => {
    if (url) {
      try {
        const config = { contentType: "image/*", level: "public" };
        const file = await getSignedUrl(url)
        setVehicleImage(file);
      } catch (error) {
        snackbar({
          message: error,
          variant: SnackBarVariant.ERROR
        });
      }
    }
  };

  const [
    getVehicle,
    { loading: getVehicleLoading, data: vehicleAPIData }
  ] = useLazyQuery(GET_VEHICLE_DATA, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    let smartcar: ISmartcarData | undefined;
    props.smartcarData?.forEach((ele, idx) => {
      if (ele.id == props.vehicleId) smartcar = props.smartcarData && props.smartcarData[idx];
    })
    setSmartcarVehicle(smartcar);
  }, [props.smartcarData, props.vehicleId])

  useEffect(() => {
    if (vehicleAPIData?.vehicle) {
      setTimeZone(vehicleAPIData.vehicle.branch);
      setVehicleData(vehicleAPIData.vehicle)
      getVehicleImage(vehicleAPIData.vehicle.imageUrl);
    }
  }, vehicleAPIData)

  const fetchData = async () => {
    try {
      props.setData(undefined);
      const { data: axiosData } = await api.getTelematicsMapData({
        esn: props.esn,
        hours: trackingHistory
      });
      if (axiosData) {
        const formattedData: ITelematicsData = {
          milesDriven: axiosData.milesDriven || "",
          engineHours: axiosData.engineHours || "",
          fuelUsage: axiosData.fuelUsage || "",
          stoppageHours: axiosData.stoppageHours || "",
          mapFlagsData: {
            eventTime: axiosData.mapFlagsData?.eventTime || [],
            speedLimit: axiosData.mapFlagsData["speedlimit"] || [],
            magnitude: axiosData.mapFlagsData?.magnitude || [],
            Key: axiosData.mapFlagsData?.Key || [],
            latLong: axiosData.mapFlagsData["latlong"] || [],
          },
          mapRoutesData: {
            eventTime: axiosData.mapRoutesData?.eventTime || [],
            latLong: axiosData.mapRoutesData["latlong"] || []
          }
        }
        props.setData(formattedData);
      }
    }
    catch (error) {
      snackbar({
        message: "Problem loading telematics data",
        variant: SnackBarVariant.ERROR
      });
    }
  }

  useEffect(() => {
    if (props.esn) {
      getVehicle({
        variables: {
          id: props.vehicleId
        }
      });
      fetchData();
      setVehicleData(undefined);
    }
  }, [props.esn])

  useEffect(() => {
    if (props.vehicleId) {
      getVehicle({
        variables: {
          id: props.vehicleId
        }
      });
      setVehicleData(undefined);
    }
  }, [props.vehicleId])

  useEffect(() => {
    if (props.esn) {
      fetchData();
    }
  }, [trackingHistory])

  const handleTrackingHistory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrackingHistory(parseInt(e.target.value))
  }

  const onLegendClick = (legend: string) => {
    const tempLegends = [...props.selectedEvents];
    const legendIndex = tempLegends.findIndex((item) => item === legend);
    if (legendIndex === -1) {
      tempLegends.push(legend)
    }
    else {
      tempLegends.splice(legendIndex, 1);
    }
    props.handleSelectEvents(tempLegends);
  }

  return (
    <>
      <Slide direction="down" in={props.active} mountOnEnter unmountOnExit>
        <div className={styles.cardLeft}>
          <IconButton
            edge="start"
            onClick={() => {
              props.handleClose();
              props.setData();
              setTrackingHistory(2);
            }}
            aria-label="Go back"
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <div className="flex fill col-flex">
            {props.esn ?
              <h2>Tracking history (hours)</h2> :
              <h2>Vehicle Details </h2>
            }
            {props.esn &&
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="tracking-history"
                  name="trackingHistory"
                  value={trackingHistory}
                >
                  {
                    [2, 4, 8, 12, 24].map((item) => {
                      return (
                        <FormControlLabel
                          key={item}
                          value={item}
                          control={
                            <Radio
                              checked={item === trackingHistory}
                              checkedIcon={<RadioButtonCheckedIcon style={{ fontSize: 16 }} />}
                              icon={<RadioButtonUncheckedIcon style={{ fontSize: 16 }} />}
                              value={item}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleTrackingHistory(e)
                              }}
                            />
                          }
                          label={<span className="flex" style={{ paddingTop: 3, marginLeft: -5, marginRight: 5 }}>{item}</span>}
                        />
                      )
                    })
                  }
                </RadioGroup>
              </FormControl>}
          </div>
        </div>
      </Slide>
      <Slide direction="left" in={props.active} mountOnEnter unmountOnExit>
        <div className={styles.cardRight}>
          <div className={styles.vehicleInfoBox}>
            {vehicleData ? <>
              <div className="flex">
                <div className={styles.vehicleImage}>
                  {vehicleImage ?
                    <img
                      src={vehicleImage}
                      alt="vehicle"
                    /> : null}
                </div>
                <div className="flex fill col-flex padding-regular font-medium">
                  <span style={{ justifyContent: "space-between" }} className="flex fill bold">
                    {vehicleData.model} <br />{vehicleData.make}
                    {props.esn &&
                      <IconButton
                        onClick={() => {
                          getVehicle({
                            variables: {
                              id: props.vehicleId
                            }
                          });
                          fetchData()
                        }}
                        style={{ marginTop: 5, padding: 0, height: 0 }} aria-label="refresh"  >
                        {
                          props.data ? (
                            <RefreshIcon fontSize="small" />
                          ) :
                            <CachedIcon fontSize="small" />
                        }
                      </IconButton>
                    }
                  </span>
                  <div className="flex cross-center space-between">
                    <span className="flex font-small cross-center">
                      <OfflineBoltIcon fontSize="small" />
                      <span>{`${vehicleData.cylinderCapacity}`}</span>
                    </span>
                    <span className="flex font-small cross-center">
                      <LocalGasStationIcon fontSize="small" />
                      <span>{vehicleData.fuelType}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex padding-left">
                {props.esn ?
                  <div className="flex fill col-flex">
                    <p className={styles.label}>Telemetry Device</p>
                    <span>{props.esn}</span>
                  </div>
                  : <></>
                }
                <div className="flex fill col-flex">
                  <p className={styles.label}>Licence Plate</p>
                  <span>{vehicleData.licencePlate}</span>
                </div>
              </div>
              {props.vehicleLastLocation &&
                <div className="flex col-flex padding-top padding-left padding-bottom">
                  <div className="flex fill col-flex">
                    <p className={styles.label}>Last Location</p>
                    <span>{props.vehicleLastLocation}</span>
                    {props.lastLocationTime && <Typography style={{ marginTop: 3 }} variant="body2">{getLocalizedDateFormat(country, props.lastLocationTime, DATE_TYPE.EXPANDED, currentTimeZone)}</Typography>}
                  </div>
                </div>}
              {smartcarVehicle?.latitude && smartcarVehicle?.longitude &&
                <div className="flex col-flex padding-top padding-left padding-bottom">
                  <div className="flex fill col-flex">
                    <p className={styles.label}>Latitude</p>
                    <span>{smartcarVehicle.latitude}</span>
                    <p className={styles.label}>Longitude</p>
                    <span>{smartcarVehicle.longitude}</span>
                  </div>
                </div>
              }
            </> : null}
            {props.data ? <>
              <div className="flex col-flex padding-top padding-left">
                <p className={styles.label}>For Selected Period - </p>
              </div>
              <div className="flex padding-top padding-left">
                <div className={`${styles.coloredInfoCard} ${styles.teal}`}>
                  <p className={styles.label}>Miles driven</p>
                  <span>{props.data.milesDriven}</span>
                </div>
                <div className={`${styles.coloredInfoCard} ${styles.blue}`}>
                  <p className={styles.label}>Engine hours</p>
                  <span>{props.data.engineHours}</span>
                </div>
              </div>
              <div className="flex padding-top padding-left padding-bottom">
                <div className={`${styles.coloredInfoCard} ${styles.orange}`}>
                  <p className={styles.label}>Fuel usage (L)</p>
                  <span>{props.data.fuelUsage}</span>
                </div>
                <div className={styles.coloredInfoCard}>
                  <p className={styles.label}>Stoppage hours</p>
                  <span>{props.data.stoppageHours}</span>
                </div>
              </div>
            </> : null}
            {smartcarVehicle ? <>
              <div className="flex padding-top padding-left">
                <div className={`${styles.coloredInfoCard} ${styles.teal}`}>
                  <p className={styles.label}>Miles Driven</p>
                  <span>{(smartcarVehicle.odometer??0).toFixed(2)}</span>
                </div>
                <div className={`${styles.coloredInfoCard} ${styles.blue}`}>
                  <p className={styles.label}>Charge Status</p>
                  <span>{smartcarVehicle.evChargeStatus?.replace("_", " ")}</span>
                </div>
              </div>
              <div className="flex padding-top padding-left padding-bottom">
                <div className={`${styles.coloredInfoCard} ${styles.orange}`}>
                  <p className={styles.label}>Fuel usage</p>
                  <span>{smartcarVehicle.fuelLevel ? `${((1 - smartcarVehicle.fuelLevel) * 100).toFixed(2)}%` : "N/A"}</span>
                </div>
                <div className={styles.coloredInfoCard}>
                  <p className={styles.label}>Battery Level</p>
                  <span>{smartcarVehicle.evBatteryLevel ? `${smartcarVehicle.evBatteryLevel * 100}%` : "N/A"}</span>
                </div>
              </div>
            </> : null}
            {((!props.data && !smartcarVehicle) || getVehicleLoading) ?
              <div className="flex cross-center main-center" style={{ height: 200 }}>
                <CircularProgress size={24} thickness={5} />
              </div> : null}
          </div>
          {props.data &&
            <div className={styles.legendBox}>
              <div className="flex wrap padding-top padding-bottom position-relative">
                {Object.keys(eventKeysMap).map((key, index) => (
                  <p key={index} onClick={() => onLegendClick(key)} className={`${styles.legend} ${props.selectedEvents.includes(key) ? styles.active : ''}`}>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g id="Group_30" data-name="Group 30" transform="translate(-876 -767)">
                          <g id="Ellipse_27" data-name="Ellipse 27" transform="translate(876 767)" fill="#fff" stroke={eventKeysMap[key].color} stroke-width="1">
                            <circle cx="6" cy="6" r="6" stroke="none" />
                            <circle cx="6" cy="6" r="5.5" fill="none" />
                          </g>
                          <circle id="Ellipse_28" data-name="Ellipse 28" cx="3" cy="3" r="3" transform="translate(879 770)" fill={eventKeysMap[key].color} />
                        </g>
                      </svg>
                    </span>
                    <span>{eventKeysMap[key].display}</span>
                  </p>
                ))}
              </div>
            </div>
          }
        </div>
      </Slide>
    </>
  )
}

export const TelematicsView = React.memo(TelematicsViewComponent);