import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import {
  CircularProgress,
  IconButton,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
import { BookingStatus, IVehicle } from "../../../../reducers/bookings/types";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  StyledTableCell,
  StyledTableRow,
  useTableStyles,
} from "../../../StyledTable";
import TableBody from "@material-ui/core/TableBody";
import MapIcon from "@material-ui/icons/Map";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import { GET_VEHICLES_FOR_BUSINESS_CUSTOMER } from "../../../../graphql/vehicles/getVehicles";
import { useLazyQuery } from "@apollo/client";
import { getSignedUrl } from "../../../../utils/getSignedUrl";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import DEFAULT_VEHICLE_ICON from "../../../../assets/icons/hatchback.svg";
import AssignDriverDialog from './AssignDriverDialog';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { UserRole } from '../../../../reducers/auth/types';
import { DateTime as d } from "luxon"
import { getLocalizedBookedSyntex, getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { DATE_TYPE, getVehicleTypeIcon } from "../../../utils";
import { capitalizeFirstLetter } from "../../utils";

const StyledInput = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "8px 10px 9px 0 !important",
      borderColor: "#e9e9e9",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0,0,0,0.1)",
    },
  },
})(TextField);

const Vehicles = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const [filter, setFilter] = useState<string>("");
  const classes = useTableStyles();
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const [assignDriverDialog, setAssignDriverDialog] = useState<{ open: boolean, vehicle?: IVehicle }>({ open: false });

  const [getVehicles, { loading: vehiclesLoading, data: vehiclesData, error }] =
    useLazyQuery(GET_VEHICLES_FOR_BUSINESS_CUSTOMER, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    user?.businesscustomers &&
      getVehicles({
        variables: {
          businessCustomerId: user.businesscustomers.id,
          ...(user.role === UserRole.DRIVER && { driver: user.customerId })
        },
      });
  }, [user]);

  useEffect(() => {
    const fetchAndSetVehicles = async () => {
      if (vehiclesData && vehiclesData.getVehiclesForBusinessCustomer) {
        const reShapeVehicles = [];
        for (const vehicle of vehiclesData.getVehiclesForBusinessCustomer) {
          if (!vehicle.isGhostVehicle) {
            const file = await getVehicleImage(vehicle.imageUrl);
            if (file) {
              reShapeVehicles.push({ ...vehicle, imageUrl: file });
            } else {
              reShapeVehicles.push(vehicle);
            }
          }
        }
        setVehicles(reShapeVehicles);
      }
    };

    fetchAndSetVehicles();
  }, [vehiclesData]);

  let filteredVehicles = filter
    ? vehicles.filter((v) =>
      v.licencePlate.toLowerCase().includes(filter?.toLowerCase() || "")
    )
    : vehicles;

  const getVehicleImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url);
        return file;
      } catch (error: any) {
        snackbar({
          message: error,
          variant: SnackBarVariant.ERROR,
        });
      }
    }
  };

  return (
    <>
      {assignDriverDialog.open && user?.businesscustomers?.approvedDrivers && <AssignDriverDialog
        open={assignDriverDialog.open}
        onClose={() => {
          setAssignDriverDialog({
            ...assignDriverDialog,
            open: false
          });
        }}
        drivers={user?.businesscustomers?.approvedDrivers}
        vehicle={assignDriverDialog.vehicle}
        onSucces={(driver) => {
          const _vehicles = vehicles.map((v => {
            if (v.activeBookingSchedule.id === assignDriverDialog.vehicle?.activeBookingSchedule.id) {
              return {
                ...v,
                activeBookingSchedule: {
                  ...v.activeBookingSchedule,
                  driver
                }
              }
            }
            return v
          }));
          setVehicles(_vehicles);
        }}
      />}
      <div className="flex cross-center">
        <Typography variant="h2" className="semi-bold">
          {user?.role === UserRole.DRIVER ? "Assigned" : `${getLocalizedBookedSyntex(country)}`} Vehicles
        </Typography>
        {vehiclesLoading && (
          <CircularProgress
            size={20}
            thickness={5}
            style={{ marginLeft: 10 }}
          />
        )}
        <div className={styles.filters}>
          <div>
            <StyledInput
              size="small"
              placeholder="Search Licence Plate"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              InputProps={{
                style: {
                  padding: "0 0 0 8px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ opacity: 0.4 }} fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
      {!vehiclesLoading && (
        <div className="margin-top padding-top">
          <TableContainer style={{ maxHeight: "100%" }} className="px-10 row-hover">
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Licence Plate</StyledTableCell>
                  <StyledTableCell>{getLocalizedBookingSyntex(country)}</StyledTableCell>
                  <StyledTableCell>Make / Model / Colour</StyledTableCell>
                  {user?.role === UserRole.ADMIN_USER && <StyledTableCell>Driver Assigned</StyledTableCell>}
                  <StyledTableCell>
                    {user?.role === UserRole.DRIVER && "Note"}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVehicles.length ? filteredVehicles.map((vehicle, index) => {
                  const driver = user?.businesscustomers?.approvedDrivers.find((driver => driver.id === vehicle.activeBookingSchedule.driver?.id));
                  const encodedNote = encodeURIComponent(vehicle.activeBookingSchedule.driver?.note || "");
                  const searchParams = new URLSearchParams();
                  searchParams.set("booking", vehicle.activeBookingSchedule.booking.id || "");
                  searchParams.set("note", encodedNote);
                  return (
                    <>
                      <StyledTableRow
                        key={index}
                        onClick={() =>
                          history.push(`/account/vehicles/${vehicle.id}?${searchParams.toString()}`)
                        }
                      >
                        <StyledTableCell className={classes.noWhiteSpace}>
                          <div className={styles.thumbnail}>
                            {vehicle.imageUrl ? (
                              <img
                                className="img-cover"
                                src={vehicle.imageUrl}
                                alt="vehicleImg"
                              />
                            ) : (
                              <img
                                className="img-cover"
                                src={getVehicleTypeIcon(capitalizeFirstLetter(vehicle.bodyType))}
                                alt="vehicleImg"
                                style={{ objectFit: "contain" }}
                              />
                            )}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          {vehicle.licencePlate}
                        </StyledTableCell>
                        <StyledTableCell>
                          {vehicle.activeBookingSchedule.booking.referenceNumber}<br />
                          <span className="opacity50">
                            {vehicle.activeBookingSchedule.booking.pickupDateTime &&
                              getLocalizedDateFormat(country, vehicle.activeBookingSchedule.booking.pickupDateTime, DATE_TYPE.EXPANDED,vehicle.activeBookingSchedule.booking.branch?.timeZone)
                            } to<br />
                            {vehicle.activeBookingSchedule.booking.dropoffDateTime &&
                              getLocalizedDateFormat(country, vehicle.activeBookingSchedule.booking.dropoffDateTime, DATE_TYPE.EXPANDED,vehicle.activeBookingSchedule.booking.branch?.timeZone)
                            }
                          </span>

                        </StyledTableCell>
                        <StyledTableCell>
                          {vehicle.make} / {vehicle.model} / {vehicle.colour}
                        </StyledTableCell>
                        {user?.role === UserRole.ADMIN_USER && <StyledTableCell>
                          {driver ?
                            <div className="flex">
                              <span
                                className={styles.driverButton}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setAssignDriverDialog({
                                    open: true,
                                    vehicle
                                  })
                                }}
                              >
                                <p>{`${driver.firstName} ${driver.lastName}`}</p>
                                <AutorenewRoundedIcon style={{ fontSize: 16, marginLeft: 3 }} />
                              </span>
                            </div>
                            :
                            <IconButton
                              size="medium"
                              edge="start"
                              onClick={(e) => {
                                e.stopPropagation();
                                setAssignDriverDialog({
                                  open: true,
                                  vehicle
                                })
                              }}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          }</StyledTableCell>}
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                          {driver &&
                            <Tooltip
                              title="Note"
                              placement="top"
                              PopperComponent={(props) => <Popper {...props} transition>
                                <div className="" style={{ maxWidth: 300, background: "#fff", border: "1px solid #e2e2e2", borderRadius: 6, boxShadow: "0 0 6px 1px rgba(0,0,0,0.1)" }}>
                                  <Typography variant="body2" style={{ padding: "8px 14px", borderBottom: "1px solid #e2e2e2", fontWeight: 700 }}>
                                    {vehicle.activeBookingSchedule.driver?.note ? "Note" : "No note added!"}
                                  </Typography>
                                  {vehicle.activeBookingSchedule.driver?.note && <Typography variant="body2" style={{ padding: "8px 14px 12px 14px" }}>
                                    {vehicle.activeBookingSchedule.driver?.note || ""}
                                  </Typography>}
                                </div>
                              </Popper>}
                            >
                              <span className={styles.driverButton}>
                                <ChatOutlinedIcon style={{ fontSize: 16 }} />
                              </span>
                            </Tooltip>}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  );
                }) : (
                  <StyledTableCell colSpan={20}>
                    <Typography style={{ textAlign: "center", opacity: 0.5 }}> {user?.role === UserRole.DRIVER ? "Vehicles will appear once assigned to you!" : "Sorry, no matching records found!"}</Typography>
                  </StyledTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default Vehicles;
