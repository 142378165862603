import {
  IConsumerWebsiteState
} from "./types";
export const initialState: IConsumerWebsiteState = {
  consumerWebsite: {
    appName: '',
    allowedRateTypes: [],
    allowedBillingFrequencies: [],
    domain: '',
    websiteTitle: '',
    websiteIcon: '',
    websiteLogo: '',
    websiteHeader: '',
    hasCarousel: false,
    hasGallery: false,
    branding: {
      buttonColor: '',
      accentColor: ''
    },
    organisationId: '',
    tenantId: '',
    branchIds: [],
    bookingQuestions: [],
    websiteDescription: '',
    carousel: [],
    gallery: [],
    privacyPolicyURL: '',
    termsAndConditionsURL: '',
    logoSize: 40,
    htmlDescription: false,
    branches: [],
    tenancy: {
      chargebeeAddons: []
    },
    organisation: {
      address: {
        city: "",
        country: "",
        fullAddress: "",
        state: "",
        street: "",
        zipcode: ""
      },
      currency: "",
      emailId: "",
      id: "",
      locale: "",
      logoUrl: "",
      name: "",
      orgRegNumber: "",
      orgVatNumber: "",
      owner: "",
      phoneNumber: {
        phone: "",
        country: ""
      },
      rate: null,
      stripeAccountId: "",
      taxes: [],
      termsAndConditionsUrl: "",
      branches: [],
      requiresCustomerVerification: false,
    },
    enableBusinessCustomerSignup: false,
    bookingTypes: []
  }
};
