import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import styles from "./index.module.css";
import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import { Field, FieldProps, Form, Formik } from "formik";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  CustomerVerificationStatus,
  IBusinessCustomerApprovedDriverInput,
  ICustomer,
  UserRole,
} from "../../reducers/auth/types";
import { useHistory } from "react-router-dom";
import { DateTime as d } from "luxon";
import { getSignedUrl } from "../../utils/getSignedUrl";
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";
import ImageGallery from "../ImageGallery/ImageGallery";
import HttpHeader from '../../header';
import { DATE_TYPE, formatGraphQLErrorMessage, getUploadedImageByKey, returnfileContentTypeAndExtention } from '../utils';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_CUSTOMER } from '../../graphql/user/updateUser';
import FlatPickerBar from '../FlatPicker';
import ImageUpload from './ImageUpload';
import * as UUID from "uuid";
import { useSelector } from "react-redux";
import { IAppState } from "../../store";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { GET_ALL_DOCUMENT_TYPES } from "../../graphql/customers/getAllDocumentTypesQuery";
import dayjs from "dayjs";
import { getLocalizedDateFormat, getLocalizedInsuranceNumberSyntex } from "../../utils/localized.syntex";

const httpHeader = HttpHeader.getInstance();

const StyledInput = withStyles(() =>
  createStyles({
    root: {
      border: "1px solid #c0c0c0",
      borderRadius: 6,
      padding: "8px 10px",
      marginTop: 5,
      '&.Mui-focused': {
        borderColor: "var(--primary-color)"
      }
    },
  }),
)(InputBase);
interface ILicenseDocument {
  documentKey?: string;
  document?: string;
}
interface IProps {
  customer: ICustomer | IBusinessCustomerApprovedDriverInput;
  onAccount?: boolean
}

const licenseImageInitial: ILicenseDocument[] = [{
  documentKey: "",
  document: ""
}, {
  documentKey: "",
  document: ""
}]

export interface IDocumentType {
  id?: string;
  typeName: string;
  hasExpiry: boolean;
  description?: string;
  reminderBefore?: number
}

export interface IUploadDocumentValues {
  id: string;
  title: string;
  expiryDate: string;
  documentType: string;
  reminderBefore: number;
  createdAt: string;
  documentKey: string;
  documentName: string;
}

const defaultValues: IUploadDocumentValues = {
  id: "",
  title: "",
  createdAt: new Date().toISOString(),
  documentKey: "",
  documentType: "",
  reminderBefore: 0,
  expiryDate: "",
  documentName: ""
};

const ProfileScreen: React.FC<IProps> = ({ customer, onAccount }) => {
  const snackbar = useSnackBar();
  const history = useHistory();
  const { _e_ } = useSelector((state: IAppState) => state.authReducer);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { country } = website.organisation.address;
  const [licenseImages, setLicenseImages] = useState<ILicenseDocument[]>(licenseImageInitial);
  const [open, setOpen] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const _newImageKeys = useMemo(() => ["", ""], []);
  const [profileImgUrl, setProfileImgUrl] = useState<string>("");
  const [profileImg, setProfileImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [allDocumentTypes, setAllDocumentTypes] = useState<IDocumentType[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [declinedNote, setDeclinedNote] = useState<string>("");
  const [selectedDocument, setSelectedDocument] = useState<IDocumentType>();
  const [inputValues, setInputValues] = useState<IUploadDocumentValues>(
    defaultValues
  );

  const [getAllDocumentTypes, { data: allDocumentTypesData, loading: documentTypesLoading }] = useLazyQuery(GET_ALL_DOCUMENT_TYPES);
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const isDriver = user?.role === UserRole.DRIVER;

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: (data) => {
      setEditing(false);
      window.location.reload();
      snackbar({
        message: "Customer Information Saved",
        variant: SnackBarVariant.SUCCESS
      });
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });

  useEffect(() => {
    const promises: any[] = [];
    try {
      if (
        customer &&
        customer.license &&
        customer.license.images &&
        customer.license.images.length
      ) {
        const dlImages: ILicenseDocument[] = [...licenseImageInitial];
        customer.license.images.map((img: string, index: number) => {
          _newImageKeys[index] = img
          promises.push(
            (async () => {
              try {
                const data: any = await getImage(img);
                dlImages[index] = {
                  documentKey: img,
                  document: data,
                };
              } catch (error) {
                return;
              }
            })()
          );
        });
        Promise.all(promises).then(() => {
          setLicenseImages(dlImages);
        });
      } else {
        customer.license.images = [];
      }
    } catch (error) {
      throw error;
    }
  }, [customer]);

  useEffect(() => {
    getAllDocumentTypes();
    if (customer && customer.showDeclinedNote) {
      const sortedNotes = [...customer.notes].sort((a, b) => d.fromISO(b.createdDate).toMillis() - d.fromISO(a.createdDate).toMillis());
      const latestDeclinedNote = sortedNotes.find((note) => note.description.includes("Customer verification declined -"));
      if (latestDeclinedNote) {
        const fullNote = latestDeclinedNote.description;
        const reason = fullNote.replace("Customer verification declined -", "").trim();
        setDeclinedNote(reason);
      }
    }
    (async () => {
      if (customer && customer.profilePicture) {
        const image = await getUploadedImageByKey(customer.profilePicture);
        setProfileImg(image);
      }
    })();
  }, [customer]);

  useEffect(() => {
    if (allDocumentTypesData && allDocumentTypesData.getAllDocumentTypes) {
      const enabledDocTypes = (allDocumentTypesData.getAllDocumentTypes).filter((obj:any) => {
        return obj.enabledOnPortal === true
      })
      setAllDocumentTypes(enabledDocTypes);
    }
  }, [allDocumentTypesData]);

  const getImage = async (url: string) => {
    if (url) {
      try {
        const file = await getSignedUrl(url);
        return file;
      } catch (error) {
        snackbar({
          message: error as string,
          variant: SnackBarVariant.ERROR,
        });
      }
    }
  };

  const uploadProfileImg = async (
    e: any,
  ) => {
    try {
      var filesArr = Array.prototype.slice.call(e.target.files);
      const file = filesArr[0];
      if (file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
        const { fileExtension, contentType } = returnfileContentTypeAndExtention(file);
        const uniqueId = UUID.v4();
        const key = `${uniqueId}.${fileExtension}`;
        if (_e_) {
          setLoading(true);
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setProfileImgUrl(key);
                snackbar({
                  message: "Profile Image uploaded successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                const uploadedFile = await getUploadedImageByKey(key);
                if (uploadedFile) {
                  setProfileImg(uploadedFile);
                }
                setProfileImg(uploadedFile);
                setLoading(false);
              }
            });
        }
      } else {
        return snackbar({
          message: "Please only upload .jpeg/.jpg/.png image format!",
          variant: SnackBarVariant.ERROR
        });
      }
    } catch (err: any) {
      snackbar({
        message: err.message,
        variant: SnackBarVariant.ERROR
      });
    }
  };

  const isImageFileType = (fileType: string) => {
    return fileType === "image/png" || fileType === "image/jpg" || fileType === "image/jpeg";
  }

  const checkUploadFileFormat = (fileType: string) => {
    if (
      fileType === "application/pdf" ||
      fileType === "image/png" ||
      fileType === "image/jpg" ||
      fileType === "image/jpeg"
    ) {
      return true;
    }
  };


  // Upload the document to s3
  async function uploadDocument(
    e: any
  ) {
    try {
      if (!selectedDocument) {
        return snackbar({
          message: "Please select document type",
          variant: SnackBarVariant.ERROR
        });
      }
      if (selectedDocument && selectedDocument.hasExpiry && !inputValues.expiryDate) {
        return snackbar({
          message: "Please select document expiry date",
          variant: SnackBarVariant.ERROR
        });
      }
      var filesArr = Array.prototype.slice.call(e.target.files);
      const file = filesArr[0];
      // 50MB limit exceeded
      if (file.type === "application/pdf" && file.size > 52428800) {
        throw new Error("File size exceeded limit of 50MB");
      }
      if (isImageFileType(file.type) && file.size > 5242880) {
        throw new Error("File size exceeded limit of 5MB");
      }
      const fileName = file.name.split(".")[0];
      setUploadInProgress(true);
      if (checkUploadFileFormat(file.type)) {
        const { fileExtension, contentType } = returnfileContentTypeAndExtention(file);
        const uniqueId = UUID.v4();
        const key = `${website.tenantId}/${uniqueId}.${fileExtension}`;
        if (_e_) {
          await _e_
            .add({
              name: key,
              file: file,
              complete: async () => {
                setInputValues({
                  ...inputValues,
                  id: uniqueId,
                  documentName: fileName,
                  documentKey: key
                })
                snackbar({
                  message: "Document uploaded successfully",
                  variant: SnackBarVariant.SUCCESS
                });
                setUploadInProgress(false);
              }
            });
        }
      } else {
        setUploadInProgress(false);
        setTimeout(() => {
          return snackbar({
            message: "Please only upload PDF, PNG, JPG or JPEG files",
            variant: SnackBarVariant.ERROR
          });
        }, 2000);
      }
    } catch (err: any) {
      snackbar({
        message: formatGraphQLErrorMessage(err.message),
        variant: SnackBarVariant.ERROR
      });
    }
  }

  return (
    <div className={styles.column}>
      {!onAccount && <div className="flex cross-center margin-bottom padding-bottom">
        <div className="flex cross-center">
          <IconButton
            size="small"
            edge="start"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="semi-bold">Driver Details</Typography>
        </div>
        <span
          className="flex fill margin-left"
          style={{ borderBottom: "1px solid #e9e9e9", marginTop: 4 }}
        ></span>
      </div>}
      <Formik
        initialValues={customer}
        onSubmit={(values) => {
          const { id, tenantId, notes, showDeclinedNote, approvedDrivers,  ...rest } = values;
          if (inputValues && inputValues?.documentKey) {
            if (!(rest.documents && typeof rest.documents !== "string" && rest.documents.length)) {
              rest.documents = [];
              rest.documents.push(inputValues);
            } else {
              rest.documents = [...rest.documents, inputValues]
            }
          }
          updateCustomer({
            variables: {
              customer: {
                ...rest,
                profilePicture: profileImgUrl ? profileImgUrl : rest.profilePicture,
                license: {
                  ...rest.license,
                  images: _newImageKeys,
                }
              },
              customerId: values.id
            }
          });
        }}
      >
        {(formikProps) => (
          <Form className={styles.profile}>
            <div className="flex cross-center margin-top padding-bottom">
              <div className={styles.avatar}>
                {customer.profilePicture && !editing ? (
                  <span>
                    <img src={profileImg} alt={"profile image"} height={"70"} width={"70"} />
                  </span>
                ) : (
                  <>
                    {editing ? (
                      <>
                        <span>
                          <label htmlFor={"profileImg"} style={{ cursor: "pointer" }}>
                            {profileImg ? (
                              <img src={profileImg} alt={"profile image"} height={"70"} width={"70"} />
                            ) : (
                              <span>
                                <CloudUploadIcon />
                              </span>
                            )}
                          </label>
                        </span>
                        <input accept="image/*" style={{ display: 'none' }} onChange={(e: any) => { uploadProfileImg(e); e.target.value = null }} id={"profileImg"} type="file" />
                      </>
                    ) : (
                      <span className="font-large bold">
                        {customer.firstName.slice(0, 1).toUpperCase()}
                        {customer.lastName.slice(0, 1).toUpperCase()}
                      </span>
                    )}
                  </>
                )}

              </div>
              <div className="flex fill col-flex margin-left margin-right">
                <Typography
                  variant="h3"
                  className="semi-bold"
                  style={{ marginLeft: 1, marginBottom: 4 }}
                >
                  {customer.firstName} {customer.lastName}
                </Typography>
                <span className="flex cross-center font-medium opacity50">
                  <EmailIcon style={{ fontSize: 17, marginRight: 3 }} />
                  <span>{customer.email}</span>
                  <PhoneIcon
                    style={{ fontSize: 17, marginRight: 3, marginLeft: 10 }}
                  />
                  <span className="">{customer.phoneNumber.phone}</span>
                </span>
              </div>
              {onAccount && <div className="flex cross-center">
                {!editing && <Button
                  onClick={() => {
                    setEditing(true);
                  }}
                  variant="outlined"
                  color="default" size="large"
                  disableRipple
                  style={{ textTransform: "initial", fontSize: 14, fontWeight: 500, padding: "6px 14px" }}
                >
                  Edit
                </Button>}
                <span className='margin-left'></span>
                <Button
                  onClick={() => {
                    httpHeader.resetHeaders();
                    localStorage.clear();
                    window.location.replace("/");
                  }}
                  variant="outlined"
                  color="default" size="large"
                  disableRipple
                  style={{ textTransform: "initial", fontSize: 14, fontWeight: 500, padding: "6px 14px", whiteSpace: "nowrap" }}
                >
                  Log out
                </Button>
              </div>}
            </div>
            <div className={styles.card}>
              <Grid container spacing={2}>
                {website.organisation.requiresCustomerVerification && !isDriver && (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="body1">
                        Customer Verification Status :
                        <span style={{
                          fontWeight: "bold", color: customer.status === CustomerVerificationStatus.PENDING ||
                            customer.status === CustomerVerificationStatus.IN_REVIEW ? "organge" :
                            customer.status === CustomerVerificationStatus.DECLINED ? "red" : "green"
                        }}> {customer.status} </span>
                      </Typography>
                    </Grid>
                    {declinedNote && customer.status === CustomerVerificationStatus.DECLINED && (
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="body1">
                          Note:
                          <span style={{ fontWeight: "bold" }}> {declinedNote} </span>
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">{getLocalizedInsuranceNumberSyntex(country)}</Typography>
                  {!editing ? <Typography variant="body1" className="semi-bold">{customer.nationalInsuranceNumber || "N/A"}</Typography> :
                    <Field name="nationalInsuranceNumber">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            {...field}
                            placeholder="Enter National Insurance Number"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small">Profession</Typography>
                  {!editing ? <Typography variant="body1" className="semi-bold"> {customer.profession || "N/A"} </Typography> :
                    <Field name="profession">
                      {({ field, meta: { touched, error }, }: FieldProps) => (
                        <>
                          <StyledInput
                            {...field}
                            placeholder="Enter Your Profession"
                            fullWidth
                            error={touched && Boolean(error)}
                          />
                          <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                        </>
                      )}
                    </Field>}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="margin-bottom">
                    <Typography variant="body1" className="opacity60 padding-bottom--small" > Driving Licence Number </Typography>
                    {!editing ? <Typography variant="body1" className="semi-bold"> {customer.license.licenseNumber || "N/A"} </Typography> :
                      <Field name="license.licenseNumber">
                        {({ field, meta: { touched, error }, }: FieldProps) => (
                          <>
                            <StyledInput
                              {...field}
                              placeholder="Enter Driving Licence Number"
                              fullWidth
                              error={touched && Boolean(error)}
                            />
                            <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                          </>
                        )}
                      </Field>}
                  </div>
                  {editing && <Typography variant="body1" className="opacity60 padding-bottom--small padding-top" > Driving Licence Image </Typography>}
                  <div className="flex">
                    {licenseImages.map((image, index) => {
                      return (
                        <div key={index} className={styles.dlImage}>
                          {editing ?
                            <ImageUpload
                              label={index ? "Upload back" : "Upload front"}
                              onUpload={(key, image) => {
                                _newImageKeys[index] = key
                              }}
                              id={`LICENCE_IMG_${index}`}
                            />
                            : <button
                              type="button"
                              onClick={() => image.document && setOpen(true)}
                              style={{
                                border: "none",
                                boxShadow: "none",
                                margin: 0,
                                padding: 0,
                                height: 56,
                                width: "100%",
                                cursor: "pointer",
                              }}
                            >
                              {image.document && <img src={image.document} alt="" />}
                            </button>}
                        </div>
                      );
                    })
                    }
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="margin-bottom padding-bottom">
                    <Typography variant="body1" className="opacity60 padding-bottom--small" > Licence Issued By </Typography>
                    {!editing ? <Typography variant="body1" className="semi-bold"> {customer.license.authority || "N/A"} </Typography> :
                      <Field name="license.authority">
                        {({ field, meta: { touched, error }, }: FieldProps) => (
                          <>
                            <StyledInput
                              {...field}
                              placeholder="Licence Issued By"
                              fullWidth
                              error={touched && Boolean(error)}
                            />
                            <Typography variant="body1" className={styles.inputError}>{touched && Boolean(error) && error}</Typography>
                          </>
                        )}
                      </Field>}
                  </div>
                  <div>
                    <Typography variant="body1" className="opacity60 padding-bottom--small" >  Licence Expiry Date </Typography>
                    {!editing ? <Typography variant="body1" className="semi-bold"> {customer.license.dateOfExpiry ? getLocalizedDateFormat(country, customer.license.dateOfExpiry, DATE_TYPE.CONDENSED) : "N/A"} </Typography> :
                      <FlatPickerBar
                        enableTime={false}
                        handleDateChange={(value: Date) => {
                          formikProps.setFieldValue("license.dateOfExpiry", d.fromJSDate(value).endOf("day").toUTC().toISO())
                        }}
                        label={""}
                        identifier={"dateOfExpiry"}
                        placeholderValue={" Driving Licence Expiry Date"}
                        value={formikProps.values.license.dateOfExpiry ? getLocalizedDateFormat(country, formikProps.values.license.dateOfExpiry, DATE_TYPE.CONDENSED) : ""}
                        minDate={d.now().startOf("day").toUTC().toISO()}
                        country={country}
                      />}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Typography variant="body1" className="opacity60 padding-bottom--small padding-top" > Document </Typography>
                </Grid>
                {editing ? (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="document-type-select"
                        select
                        fullWidth
                        label="Select Document"
                        value={""}
                        inputProps={{
                          onChange: (
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            const thisDocType = allDocumentTypes.find(docType => docType.typeName === event.target.value)
                            thisDocType && setInputValues({
                              ...inputValues,
                              documentType: event.target.value,
                              reminderBefore: thisDocType.reminderBefore || 7,
                              expiryDate: ""
                            });
                            if (thisDocType) {
                              setSelectedDocument(thisDocType);
                            }
                          },
                          value: inputValues.documentType
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {allDocumentTypes.map((item: any, index) => (
                          <MenuItem key={index} value={item.typeName}>
                            {item.typeName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {selectedDocument?.hasExpiry && (
                      <Grid item xs={12} sm={6} md={4}>
                        <FlatPickerBar
                          enableTime={false}
                          handleDateChange={(value: Date) => {
                            if (value) {
                              const expiryDate = d.fromJSDate(value).endOf("day").toUTC().toISO();
                              setInputValues({
                                ...inputValues,
                                expiryDate
                              });
                            }
                          }}
                          label={""}
                          identifier={"expiryDate"}
                          placeholderValue={"Expiry Date"}
                          value={getLocalizedDateFormat(country, inputValues.expiryDate, DATE_TYPE.CONDENSED)}
                          minDate={d.now().startOf("day").toUTC().toISO()}
                          country={country}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={4} style={{ marginTop: 10 }}>
                      <span>
                        <label htmlFor={"documentUpload"} style={{ cursor: "pointer" }}>
                          <span style={{
                            backgroundColor: "black",
                            color: "#e2e2e2",
                            fontSize: 16,
                            fontWeight: "bold",
                            padding: "10px 16px",
                            borderRadius: 4
                          }}>
                            {uploadInProgress ? <CircularProgress size={20} thickness={5} /> : "Add File"}
                          </span>
                        </label>
                      </span>
                      <input accept="image/jpg, image/jpeg, image/png, application/pdf" style={{ display: 'none' }} onChange={(e: any) => { uploadDocument(e); e.target.value = null }} id={"documentUpload"} type="file" />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Table style={{ minWidth: 420 }} aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Document Type</TableCell>
                          <TableCell align="right">Document Name</TableCell>
                          <TableCell align="right">Expiry Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customer && customer.documents && customer.documents.length > 0 ? customer.documents.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {row.documentType}
                            </TableCell>
                            <TableCell align="right">{row.documentName}</TableCell>
                            <TableCell align="right">{row.expiryDate ? getLocalizedDateFormat(country, row.expiryDate, DATE_TYPE.CONDENSED) : "N/A"}</TableCell>
                          </TableRow>
                        )) : (
                          <div style={{ marginLeft: 18 }}>
                            <span style={{ fontSize: 12 }}>No data found</span>
                          </div>
                        )}
                      </TableBody>
                    </Table>
                  </>
                )}
              </Grid>
              {open && (
                <ImageGallery
                  title={"Licence Images"}
                  open={open}
                  handleClose={() => setOpen(false)}
                  images={
                    licenseImages && licenseImages.length > 0
                      ? licenseImages.map((image) => image["document"])
                      : []
                  }
                />
              )}
            </div>
            {editing && <div style={{ marginTop: 30 }}>
              <Button
                onClick={() => {
                  setEditing(false);
                  formikProps.resetForm();
                }}
                variant="outlined"
                color="default" size="large"
                disableRipple
                style={{ textTransform: "initial", fontSize: 14, fontWeight: 500, padding: "6px 14px" }}
              >
                Cancel
              </Button>
              <span className="margin-left"></span>
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: "var(--primary-color)",
                  textTransform: "initial",
                  boxShadow: "none",
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#fff"
                }}
                size="large"
                disableRipple
              >
                Save
              </Button>
            </div>}
          </Form>
        )}
      </Formik>
    </div >
  );
};

export default ProfileScreen;
